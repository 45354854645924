import React from 'react'

export default function Footer() {
return (
<footer class="main-footer">
    <div class="footer-top">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-6 col-md-6 col-sm-12 footer-column">
                    <div class="logo-widget footer-widget">
                        <figure class="footer-logo"><a href="index"><img src="assets/images/footer-logo.png" alt=""
                                    style={{width: "35%"}} /></a></figure>
                        <div class="text">
                            <p>Indignation and dislike men who are so beguiled and demoralized by the charms of pleasure
                                of the moment, so blinded by desire that they cannot foresee pain trouble.</p>
                        </div>
                        {/* <div class="inner">
                            <div class="icon"><i class="flaticon-pdf"></i></div>
                            <h6><a href="">Guidence Notes</a></h6>
                            <h5><a href="">Visa Application Form</a></h5>
                        </div> */}
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 footer-column">
                    <div class="links-widget footer-widget">
                        <div class="widget-title">
                            <h4>Quick Links</h4>
                        </div>
                        <div class="widget-content">
                            <ul class="links-list clearfix">
                                {/* <li><a href="">Career</a></li>
                                <li><a href="">Agents</a></li> */}
                                <li><a href="/about">About</a></li>
                                <li><a href="/contact">Contact</a></li>
                                {/* <li><a href="">News</a></li>
                                <li><a href="">Webinars</a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                    <div class="links-widget footer-widget">
                        <div class="widget-title">
                            <h4>Contact Information</h4>
                        </div>
                        <div class="widget-content">
                            <ul class="links-list clearfix">
                                <li style={{color: "#b8bfc5"}}>
                                    <strong>Contact No: </strong>
                                    +923434499999
                                    +923216019999
                                    +923009605004
                                    +924833252928
                                </li>
                                <li style={{color: "#b8bfc5"}}> 
                                    <strong>Addres: </strong>
                                    89-C, 1st floor Zafar Book Depot, nearby zafrullah chowk.
                                    SATELLITE TOWN SARGODHA 40100 Pakistan
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div class="col-lg-2 col-md-6 col-sm-12 footer-column">
                    <div class="links-widget footer-widget">
                        <div class="widget-title">
                            <h4>Our Services</h4>
                        </div>
                        <div class="widget-content">
                            <ul class="links-list clearfix">
                                <li><a href="">Business</a></li>
                                <li><a href="">Evaluation</a></li>
                                <li><a href="">Migrate</a></li>
                                <li><a href="">Study</a></li>
                                <li><a href="">Counselling</a></li>
                                <li><a href="">Work / Career</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 footer-column">
                    <div class="links-widget footer-widget">
                        <div class="widget-title">
                            <h4>Support Links</h4>
                        </div>
                        <div class="widget-content">
                            <ul class="links-list clearfix">
                                <li><a href="">Pay Online</a></li>
                                <li><a href="">Career</a></li>
                                <li><a href="">FAQ’s</a></li>
                                <li><a href="">Appointment</a></li>
                                <li><a href="">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                {/* <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                    <div class="gallery-widget footer-widget">
                        <div class="widget-title">
                            <h4>Our Gallery</h4>
                        </div>
                        <div class="widget-content">
                            <ul class="image-list clearfix">
                                <li>
                                    <figure class="image">
                                        <img src="assets/images/resource/footer-1.jpg" alt="" />
                                        <a href="assets/images/resource/footer-1.jpg" class="lightbox-image"
                                            data-fancybox="gallery"><i class="flaticon-zoom-in"></i></a>
                                    </figure>
                                </li>
                                <li>
                                    <figure class="image">
                                        <img src="assets/images/resource/footer-2.jpg" alt="" />
                                        <a href="assets/images/resource/footer-2.jpg" class="lightbox-image"
                                            data-fancybox="gallery"><i class="flaticon-zoom-in"></i></a>
                                    </figure>
                                </li>
                                <li>
                                    <figure class="image">
                                        <img src="assets/images/resource/footer-3.jpg" alt="" />
                                        <a href="assets/images/resource/footer-3.jpg" class="lightbox-image"
                                            data-fancybox="gallery"><i class="flaticon-zoom-in"></i></a>
                                    </figure>
                                </li>
                                <li>
                                    <figure class="image">
                                        <img src="assets/images/resource/footer-4.jpg" alt="" />
                                        <a href="assets/images/resource/footer-4.jpg" class="lightbox-image"
                                            data-fancybox="gallery"><i class="flaticon-zoom-in"></i></a>
                                    </figure>
                                </li>
                                <li>
                                    <figure class="image">
                                        <img src="assets/images/resource/footer-5.jpg" alt="" />
                                        <a href="assets/images/resource/footer-5.jpg" class="lightbox-image"
                                            data-fancybox="gallery"><i class="flaticon-zoom-in"></i></a>
                                    </figure>
                                </li>
                                <li>
                                    <figure class="image">
                                        <img src="assets/images/resource/footer-6.jpg" alt="" />
                                        <a href="assets/images/resource/footer-6.jpg" class="lightbox-image"
                                            data-fancybox="gallery"><i class="flaticon-zoom-in"></i></a>
                                    </figure>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="auto-container">
            <div class="bottom-inner clearfix">
                <div class="copyright pull-left">
                    <p>&copy; 2022 <a href="">Eclati</a>. All Rights Reserved.</p>
                </div>
                <ul class="footer-nav pull-right clearfix">
                    <li><a href="">Privacy Policy</a></li>
                    <li><a href="">Term Of Use</a></li>
                    <li><a href="">Support</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>
)
}