import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap(){
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

  return (

    <>
     <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1730851.8100181993!2d70.37573889375!3d32.080878700000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392177b91c5d9015%3A0x8bfb0574940b6134!2sEclat%20International%20Institute!5e0!3m2!1sen!2s!4v1701970445050!5m2!1sen!2s"
     style={{border: "0", width:"100%" , height:"100%"}} allowfullscreen="" loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"></iframe>
    </>
    // Important! Always set the container height explicitly
    // <div style={{ height: '100vh', width: '100%' }}>
    //   <GoogleMapReact
    //     bootstrapURLKeys={{ key: "" }}
    //     defaultCenter={defaultProps.center}
    //     defaultZoom={defaultProps.zoom}
    //   >
    //     <AnyReactComponent
    //       lat={59.955413}
    //       lng={30.337844}
    //       text="My Marker"
    //     />
    //   </GoogleMapReact>
    // </div>
  );
}