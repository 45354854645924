import React from 'react'
import Header from './Includes/Header'
import Footer from './Includes/Footer'
import './Coaching.css'
export default function Coaching() {
  return (
  <>
  <Header/>
  <div class="boxed_wrapper about">
    {/* <!-- Page Title --> */}
        <section class="page-title page-title4">
            <div class="auto-container">
                <div class="content-box clearfix">
                    <div class="title centred">
                        <h6>COACHING CLASSES</h6>
                        <h1>Our Training & Certification</h1>
                    </div>
                    <ul class="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li><a href="/coaching">About</a></li>
                        <li>About Eclati</li>
                    </ul>
                </div>
            </div>
        </section>
        {/* <!-- End Page Title --> */}

        {/* <!-- about-style-four --> */}
        <section class="about-style-four">
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                        <div class="image-box">
                            <figure class="image"><img src="assets/images/resource/about-7.jpg" alt=""/></figure>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="content_block_two">
                            <div class="content-box">
                                <div class="sec-title">
                                    <h6>STANDARDIZED TESTS</h6>
                                    <h2>World Class Coaching For Student & Professionals</h2>
                                </div>
                                <div class="text">
                                    <p>Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.</p>
                                    <span>Standardized Test for Visa Applicants are of Two Types:</span>
                                </div>
                                <ul class="list clearfix">
                                    <li>Academic Standardized Tests</li>
                                    <li>English Language Proficiency Tests</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="coaching.html" class="theme-btn btn-two">Get Assistance<i class="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- about-style-four end --> */}

        {/* <!-- coaching-section --> */}
        <section class="coaching-section bg-color-1">
            <div class="auto-container">
                <div class="upper-box">
                    <div class="row clearfix">
                        <div class="col-lg-6 col-md-12 col-sm-12 title-column">
                            <div class="sec-title">
                                <h6>Coaching Classes</h6>
                                <h2>Language Proficiency Tests</h2>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 text-column">
                            <div class="text">
                                <p>Pleasure and praising pain was born and will give you complete account of the of the great explorer of the truth, the master-builder.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                        <div class="coaching-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><img src="assets/images/resource/coaching-1.jpg" alt=""/></figure>
                                <div class="content-box">
                                    <div class="icon-box"><a href="coaching-details.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details.html">ielts</a></h5>
                                    <h4><a href="coaching-details.html">Coaching</a></h4>
                                </div>
                                <div class="overlay-content">
                                    <div class="icon-box"><a href="coaching-details.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details.html">ielts</a></h5>
                                    <h4><a href="coaching-details.html">Coaching</a></h4>
                                    <p>Extremely painful again is there anyone loves or pursues.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                        <div class="coaching-block-one wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><img src="assets/images/resource/coaching-2.jpg" alt=""/></figure>
                                <div class="content-box">
                                    <div class="icon-box"><a href="coaching-details-2.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-2.html">toefl</a></h5>
                                    <h4><a href="coaching-details-2.html">Coaching</a></h4>
                                </div>
                                <div class="overlay-content">
                                    <div class="icon-box"><a href="coaching-details-2.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-2.html">toefl</a></h5>
                                    <h4><a href="coaching-details-2.html">Coaching</a></h4>
                                    <p>Extremely painful again is there anyone loves or pursues.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                        <div class="coaching-block-one wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><img src="assets/images/resource/coaching-3.jpg" alt=""/></figure>
                                <div class="content-box">
                                    <div class="icon-box"><a href="coaching-details-3.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-3.html">pte</a></h5>
                                    <h4><a href="coaching-details-3.html">Coaching</a></h4>
                                </div>
                                <div class="overlay-content">
                                    <div class="icon-box"><a href="coaching-details-3.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-3.html">pte</a></h5>
                                    <h4><a href="coaching-details-3.html">Coaching</a></h4>
                                    <p>Extremely painful again is there anyone loves or pursues.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                        <div class="coaching-block-one wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><img src="assets/images/resource/coaching-4.jpg" alt=""/></figure>
                                <div class="content-box">
                                    <div class="icon-box"><a href="coaching-details-4.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-4.html">oet</a></h5>
                                    <h4><a href="coaching-details-4.html">Coaching</a></h4>
                                </div>
                                <div class="overlay-content">
                                    <div class="icon-box"><a href="coaching-details-4.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-4.html">oet</a></h5>
                                    <h4><a href="coaching-details-4.html">Coaching</a></h4>
                                    <p>Extremely painful again is there anyone loves or pursues.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- coaching-section end --> */}

        <div class="coaching-title centred">
            <div class="auto-container">
                <div class="sec-title">
                    <h6>Coaching Classes</h6>
                    <h2>Academic Standardized Tests</h2>
                </div>
            </div>
        </div>

        {/* <!-- coaching-style-three --> */}
        <section class="coaching-style-three coaching-bg-3">
            <div class="outer-container clearfix">
                <div class="coaching-block-three">
                    <div class="inner-box">
                        <div class="upper-box">
                            <span class="count-text">01</span>
                            <div class="link"><a href="coaching-details.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="lower-box">
                            <div class="text">
                                <h4><a href="coaching-details.html"><span>ielts</span> Coaching</a></h4>
                            </div>
                            <div class="overlay-text">
                                <h4><a href="coaching-details.html"><span>ielts</span> Coaching</a></h4>
                                <p>Indignation and dislike men who are so all beguileds and demoralized expounds that.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coaching-block-three">
                    <div class="inner-box">
                        <div class="upper-box">
                            <span class="count-text">02</span>
                            <div class="link"><a href="coaching-details.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="lower-box">
                            <div class="text">
                                <h4><a href="coaching-details-2.html"><span>toefl</span> Coaching</a></h4>
                            </div>
                            <div class="overlay-text">
                                <h4><a href="coaching-details-2.html"><span>toefl</span> Coaching</a></h4>
                                <p>Indignation and dislike men who are so all beguileds and demoralized expounds that.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coaching-block-three">
                    <div class="inner-box">
                        <div class="upper-box">
                            <span class="count-text">03</span>
                            <div class="link"><a href="coaching-details.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="lower-box">
                            <div class="text">
                                <h4><a href="coaching-details-3.html"><span>pte</span> Coaching</a></h4>
                            </div>
                            <div class="overlay-text">
                                <h4><a href="coaching-details-3.html"><span>pte</span> Coaching</a></h4>
                                <p>Indignation and dislike men who are so all beguileds and demoralized expounds that.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coaching-block-three">
                    <div class="inner-box">
                        <div class="upper-box">
                            <span class="count-text">04</span>
                            <div class="link"><a href="coaching-details.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="lower-box">
                            <div class="text">
                                <h4><a href="coaching-details-4.html"><span>oet</span> Coaching</a></h4>
                            </div>
                            <div class="overlay-text">
                                <h4><a href="coaching-details-4.html"><span>oet</span> Coaching</a></h4>
                                <p>Indignation and dislike men who are so all beguileds and demoralized expounds that.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- coaching-style-three end --> */}

        </div>
  <Footer/>
  </>
  )
}
