import React from 'react'
import Header from './Includes/Header'
import Footer from './Includes/Footer'
import './Visa.css'
export default function VisaDetails2() {
return (
<>
    <Header />
    <div class="boxed_wrapper about">
        {/*
        <!-- Page Title --> */}
        <section class="page-title page-title5">
            <div class="auto-container">
                <div class="content-box clearfix">
                    <div class="title centred">
                        <h6>RESIDENCE VISA</h6>
                        <h1>Imagine a Beter Future, We Make It</h1>
                    </div>
                    <ul class="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li>Residence Visa</li>
                    </ul>
                </div>
            </div>
        </section>
        {/*
        <!-- End Page Title --> */}

{/* <!-- visa-details --> */}
        <section class="visa-details">
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                        <div class="visa-sidebar default-sidebar">
                            <div class="sidebar-widget category-widget">
                                <ul class="category-list clearfix">
                                    <li><a href="visa-details.html">Student Visa<i class="flaticon-diagonal-arrow"></i></a></li>
                                    <li><a href="visa-details-2.html">Residence Visa<i class="flaticon-diagonal-arrow"></i></a></li>
                                    <li><a href="visa-details-3.html">Business Visa<i class="flaticon-diagonal-arrow"></i></a></li>
                                    <li><a href="visa-details-4.html" class="current">Tourist Visa<i class="flaticon-diagonal-arrow"></i></a></li>
                                    <li><a href="visa-details-5.html">Conference Visa<i class="flaticon-diagonal-arrow"></i></a></li>
                                    <li><a href="visa-details-6.html">Medical Visa<i class="flaticon-diagonal-arrow"></i></a></li>
                                </ul>
                            </div>
                            <div class="sidebar-widget download-widget">
                                <h4>Downloads</h4>
                                <ul class="download-list clearfix">
                                    <li>
                                        <a href="visa-details-4.html">
                                            <img src="assets/images/icons/icon-46.png" alt=""/>
                                            <h6>Application <br />Form</h6>
                                            <span>450kb</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="visa-details-4.html">
                                            <img src="assets/images/icons/icon-47.png" alt=""/>
                                            <h6>Terms & <br />Conditions</h6>
                                            <span>12mb</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="sidebar-widget sidebar-banner">
                                <div class="inner-box" style={{backgroundImage: "url(assets/images/resource/sidebar-1.jpg)"}}>
                                    <div class="bg-layer"></div>
                                    <figure class="image-layer"><img src="assets/images/resource/sidebar-1.png" alt=""/></figure>
                                    <div class="upper-box">
                                        <h6>Planning to</h6>
                                        <h2>Study <br />in Abroad</h2>
                                        <ul class="list clearfix">
                                            <li>World Class Institution</li>
                                            <li>Quality Education</li>
                                            <li>Affordable Fees</li>
                                        </ul>
                                    </div>
                                    <div class="lower-box">
                                        <p>* Terms & Conditions</p>
                                        <a href="visa-details-4.html" class="theme-btn btn-one">Make an Appointment<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 content-side">
                        <div class="visa-details-content">
                            <div class="content-one">
                                <figure class="image-box"><img src="assets/images/resource/visa-13.jpg" alt=""/></figure>
                                <div class="text">
                                    <h2>Tourist Visa Overview</h2>
                                    <p>Beguiled and demoralized by the charms of pleasure the moment blinded by desire that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their which is the same as saying through shrinking Foresee the pain and trouble.</p>
                                    <p>Charms of pleasure the moment so blinded by desires that they cannot foresee the pain and trouble that are and equal blame belongs to those who fail in their.</p>
                                </div>
                            </div>
                            <div class="content-two">
                                <div class="row clearfix">
                                    <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                        <div class="single-item">
                                            <div class="inner-box">
                                                <div class="content-box">
                                                    <div class="text">
                                                        <p>Enjoy pleasure that has annoying consequences one who avoids a pain.</p>
                                                        <h5>F1 Student Visa</h5>
                                                    </div>
                                                    <div class="image-box">
                                                        <figure class="image"><img src="assets/images/resource/visa-6.jpg" alt=""/></figure>
                                                        <span>01</span>
                                                    </div>
                                                </div>
                                                <div class="overlay-content">
                                                    <div class="image-box">
                                                        <figure class="image"><img src="assets/images/resource/visa-7.jpg" alt=""/></figure>
                                                        <div class="btn-box"><a href="coaching-details.html" class="theme-btn btn-one">REad More<i class="flaticon-next"></i></a></div>
                                                        <span>01</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                        <div class="single-item">
                                            <div class="inner-box">
                                                <div class="content-box">
                                                    <div class="text">
                                                        <p>The system & expound actual teachings of the great explorer the truth.</p>
                                                        <h5>J1 Exchange Visa</h5>
                                                    </div>
                                                    <div class="image-box">
                                                        <figure class="image"><img src="assets/images/resource/visa-8.jpg" alt=""/></figure>
                                                        <span>02</span>
                                                    </div>
                                                </div>
                                                <div class="overlay-content">
                                                    <div class="image-box">
                                                        <figure class="image"><img src="assets/images/resource/visa-9.jpg" alt=""/></figure>
                                                        <div class="btn-box"><a href="coaching-details.html" class="theme-btn btn-one">REad More<i class="flaticon-next"></i></a></div>
                                                        <span>02</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                        <div class="single-item">
                                            <div class="inner-box">
                                                <div class="content-box">
                                                    <div class="text">
                                                        <p>No one rejects, dislikes avoid plesure itself because it is pleasure.</p>
                                                        <h5>M-1 Non-Academic </h5>
                                                    </div>
                                                    <div class="image-box">
                                                        <figure class="image"><img src="assets/images/resource/visa-10.jpg" alt=""/></figure>
                                                        <span>03</span>
                                                    </div>
                                                </div>
                                                <div class="overlay-content">
                                                    <div class="image-box">
                                                        <figure class="image"><img src="assets/images/resource/visa-11.jpg" alt=""/></figure>
                                                        <div class="btn-box"><a href="coaching-details.html" class="theme-btn btn-one">REad More<i class="flaticon-next"></i></a></div>
                                                        <span>03</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="content-three">
                                <div class="upper-text">
                                    <h2>Our Tourist Visa Package</h2>
                                    <p>Moment blinded by desire that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their which is the same as shrinking.</p>
                                </div>
                                <div class="tabs-box">
                                    <div class="row clearfix">
                                        <div class="col-lg-5 col-md-12 col-sm-12 left-column">
                                            <div class="tab-btn-box">
                                                <ul class="tab-btns tab-buttons clearfix">
                                                    <li class="tab-btn active-btn" data-tab="#tab-1">
                                                        <div class="icon-box"><i class="flaticon-consulting"></i></div>
                                                        <h6>Free Consultation</h6>
                                                        <p>Get Professional Counselling</p>
                                                    </li>
                                                    <li class="tab-btn" data-tab="#tab-2">
                                                        <div class="icon-box"><i class="flaticon-document"></i></div>
                                                        <h6>Admission process</h6>
                                                        <p>Support from the Begining</p>
                                                    </li>
                                                    <li class="tab-btn" data-tab="#tab-3">
                                                        <div class="icon-box"><i class="flaticon-open-book-1"></i></div>
                                                        <h6>Coaching Classes</h6>
                                                        <p>Tests with our Live Classes</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-md-12 col-sm-12 right-column">
                                            <div class="tabs-content">
                                                <div class="tab active-tab" id="tab-1">
                                                    <div class="inner-box">
                                                        <h4>Free Consultation</h4>
                                                        <p>Occur that pleasures have to be repudiated annoyances accepted wise man therefore always holds in these matters to this principle of selection he rejects pleasures to secure other greater pleasures he endures pains to avoid worse pains.</p>
                                                        <figure class="image-box"><img src="assets/images/resource/visa-12.jpg" alt=""/></figure>
                                                    </div>
                                                </div>
                                                <div class="tab" id="tab-2">
                                                    <div class="inner-box">
                                                        <h4>Admission process</h4>
                                                        <p>Occur that pleasures have to be repudiated annoyances accepted wise man therefore always holds in these matters to this principle of selection he rejects pleasures to secure other greater pleasures he endures pains to avoid worse pains.</p>
                                                        <figure class="image-box"><img src="assets/images/resource/visa-12.jpg" alt=""/></figure>
                                                    </div>
                                                </div>
                                                <div class="tab" id="tab-3">
                                                    <div class="inner-box">
                                                        <h4>Coaching Classes</h4>
                                                        <p>Occur that pleasures have to be repudiated annoyances accepted wise man therefore always holds in these matters to this principle of selection he rejects pleasures to secure other greater pleasures he endures pains to avoid worse pains.</p>
                                                        <figure class="image-box"><img src="assets/images/resource/visa-12.jpg" alt=""/></figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="content-four">
                                <div class="upper-text">
                                    <h2>Reason for Choosing Us</h2>
                                    <p>Do not know how to pursue pleasure rationally encounter consequences that extremely painful nor again is there anyone who loves or pursues or desires.</p>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item">
                                            <div class="icon-box"><img src="assets/images/icons/icon-50.png" alt=""/></div>
                                            <div class="inner">
                                                <h4>Direct Interviews</h4>
                                                <p>Expound actual teachings to the great explorers truth.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item">
                                            <div class="icon-box"><img src="assets/images/icons/icon-51.png" alt=""/></div>
                                            <div class="inner">
                                                <h4>Faster Processing</h4>
                                                <p>Give you a completed account the expound the teachings. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item">
                                            <div class="icon-box"><img src="assets/images/icons/icon-52.png" alt=""/></div>
                                            <div class="inner">
                                                <h4>Visa Assistance</h4>
                                                <p>Give you a completed account the expound the teachings. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item">
                                            <div class="icon-box"><img src="assets/images/icons/icon-53.png" alt=""/></div>
                                            <div class="inner">
                                                <h4>Faster Processing</h4>
                                                <p>Expound actual teachings to the great explorers truth.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- coaching-details end --> */}

        </div>
        <Footer/>
</>
)
}