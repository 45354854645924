import React from 'react'
import Header from './Includes/Header'
import Footer from './Includes/Footer'
export default function Countries() {
  return (
    <>
    <Header/>
    <div class="boxed_wrapper about">
    {/* <!-- Page Title --> */}
        <section class="page-title page-title4">
            <div class="auto-container">
                <div class="content-box clearfix">
                    <div class="title centred">
                        <h6>COUNTRIES</h6>
                        <h1>Coming Soon</h1>
                    </div>
                    {/* <ul class="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li><a href="/coaching">About</a></li>
                        <li>About Eclati</li>
                    </ul> */}
                </div>
            </div>
        </section>
        {/* <!-- End Page Title --> */}
        </div>
    <Footer/>
    </>
  )
}
