import React from 'react'
import './Banner.css'
export default function Banner() {
  return (
    <>
    <div class="top-content">
    <div id="myCarousel" class="carousel slide" data-ride="carousel" >
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
      <li data-target="#myCarousel" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active"> <img  class="d-block w-100"  src="https://res.cloudinary.com/dll4d2yu7/image/upload/v1702120654/passportbg_eqemch.jpg" alt="First slide"/> 
        
        {/* <!-- Slide Text Layer --> */}
		  <div class="slide_style_left">
				<div class="row" >
				<div class="col-12 slide-text text-center">
					<h1 class="animate__animated animate__lightSpeedInLeft">Eclati</h1>
          			<h2 class="animate__animated animate__fadeInUp">A Leading Migration Agency in Pakistan</h2>
                      <p class="animate__animated animate__fadeInUp">Denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
          			{/* <a href="#" target="_blank" class="btn btn-warning btn-lg animate__animated animate__fadeInUp" >select one</a> 
		  			<a href="#" target="_blank"  class="btn btn-primary btn-lg animate__animated animate__fadeInUp">select two</a>  */}
				</div>
				</div>
				</div>
      </div>
      <div class="carousel-item"> <img  class="d-block w-100"  src="https://res.cloudinary.com/dll4d2yu7/image/upload/v1702120199/du-lich-newyouk_yxkhtd.jpg" alt="Second slide"/> 
        {/* <!-- Slide Text Layer --> */}
        <div class="slide_style_left">
				<div class="row" >
				<div class="col-12 slide-text text-center">
					<h1 class="animate__animated animate__lightSpeedInLeft">Imagine</h1>
          			<h2  class="animate__animated animate__fadeInUp">A Better Future & We Make it Happen Today</h2>
                      <p class="animate__animated animate__fadeInUp">Beguiled and demoralized by the charms of pleasure of the moment blinded by desire that cannot.</p>

          			{/* <a href="#" target="_blank" class="btn btn-warning btn-lg animate__animated animate__fadeInUp" >select one</a> 
		  			<a href="#" target="_blank"  class="btn btn-primary btn-lg animate__animated animate__fadeInUp">select two</a>  */}
				</div>
				</div>
				</div>
		  </div>
      <div class="carousel-item"> <img  class="d-block w-100"  src="https://res.cloudinary.com/dll4d2yu7/image/upload/v1702121295/Others_sgm6pf.jpg" alt="Third slide"/> 
        {/* <!-- Slide Text Layer --> */}
        <div class="slide_style_left">
				<div class="row" >
				<div class="col-12 slide-text text-center">
					<h1 class="animate__animated animate__lightSpeedInLeft">Looking</h1>
          			<h2  class="animate__animated animate__fadeInUp">To Settle In The World’s Best Countries</h2>
                      <p class="animate__animated animate__fadeInUp">Denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
          			{/* <a href="#" target="_blank" class="btn btn-warning btn-lg animate__animated animate__fadeInUp" >select one</a> 
		  			<a href="#" target="_blank"  class="btn btn-primary btn-lg animate__animated animate__fadeInUp">select two</a>  */}
				</div>
				</div>
				</div>
		  
      </div>
    </div>
    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a> </div>
</div>
    </>
  )
}
