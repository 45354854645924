import React from 'react'
import logoImage from '../../assets/images/logo-3.png';
export default function Header() {
return (
<>
    {/*
    <!-- main header --> */}
    <header className="main-header header-style-two">
        {/*
        <!-- header-top --> */}
        <div className="header-top-two">
            <div className="auto-container">
                <div className="top-inner clearfix">
                    <div className="left-column pull-left">
                        {/* <ul className="language clearfix">
                            <li>
                                <div className="check-box">
                                    <input type="radio" name="radio" id="checkbox" checked />
                                    <label htmlFor="checkbox">En</label>
                                </div>
                            </li>
                            <li>
                                <div className="check-box">
                                    <input type="radio" name="radio" id="checkbox2" />
                                    <label htmlFor="checkbox2">Es</label>
                                </div>
                            </li>
                        </ul> */}
                        <div className="phone-box"><i className="flaticon-headphones"></i>
                            <h6>Toll Free: <a href="tel:0483252928">0483252928</a></h6>
                        </div>
                    </div>
                    <div className="top-right pull-right">
                        <ul className="social-links clearfix">
                            <li>
                                <h6>Follow On</h6>
                            </li>
                            <li><a href="https://www.facebook.com/eclatii"><i className="fab fa-facebook-f"></i></a></li>
                            {/* <li><a href="index-2.html"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="index-2.html"><i className="fab fa-vimeo-v"></i></a></li> */}
                        </ul>
                        {/* <div className="search-inner">
                            <form action="index.html" method="post">
                                <div className="form-group">
                                    <input type="search" name="search-field" placeholder="Search..." required="" />
                                    <button type="submit"><i className="flaticon-loupe"></i></button>
                                </div>
                            </form>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        {/*
        <!-- header-lower --> */}
        <div className="header-lower">
            <div className="outer-box">
                <div className="auto-container">
                    <div className="main-box">
                        <div className="logo-box">
                            <div className="bg-layer"></div>
                            <figure className="logo"><a href="/"><img className='w-100' src={logoImage}
                                        alt="" /></a></figure>
                        </div>
                        <div className="menu-area clearfix">
                            {/*
                            <!--Mobile Navigation Toggler--> */}
                            <div className="mobile-nav-toggler">
                                <i className="icon-bar"></i>
                                <i className="icon-bar"></i>
                                <i className="icon-bar"></i>
                            </div>
                            <nav className="main-menu navbar-expand-md navbar-light">
                                <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                    <ul className="navigation clearfix">
                                        <li><a href="/">Home</a>
                                        </li>
                                        <li className="dropdown"><a href="/about">About</a>
                                            <ul>
                                                <li><a href="/about">About Eclati</a></li>
                                                {/* <li><a href="team.html">Our Team</a></li>
                                                <li><a href="faq.html">FAQ’s</a></li>
                                                <li><a href="error.html">404</a></li> */}
                                            </ul>
                                        </li>
                                        <li className="dropdown"><a href="/coaching">Coaching</a>
                                            <ul className="big-menu clearfix">
                                                <li><a href="/coaching">Coaching Overview</a></li>
                                                {/* <li><a href="coaching-details-5.html">GRE</a></li>
                                                <li><a href="coaching-details.html">IELTS</a></li>
                                                <li><a href="coaching-details-6.html">GMAT</a></li>
                                                <li><a href="coaching-details-2.html">TOEFL</a></li>
                                                <li><a href="coaching-details-7.html">SAT</a></li>
                                                <li><a href="coaching-details-3.html">PTE</a></li>
                                                <li><a href="coaching-details-8.html">INE</a></li>
                                                <li><a href="coaching-details-4.html">OET</a></li> */}
                                            </ul>
                                        </li>
                                        <li className="dropdown"><a href="/visa">Visa</a>
                                            <ul className="big-menu clearfix">
                                                <li><a href="/visa">Visa Overview</a></li>
                                                {/* <li><a href="visa-details-4.html">Tourist Visa</a></li>
                                                <li><a href="visa-details.html">Student Visa</a></li>
                                                <li><a href="visa-details-5.html">Conference Visa</a></li>
                                                <li><a href="visa-details-2.html">Residence Visa</a></li>
                                                <li><a href="visa-details-6.html">Medical Visa</a></li>
                                                <li><a href="visa-details-3.html">Business Visa</a></li> */}
                                            </ul>
                                        </li>
                                        <li className="dropdown"><a href="/countries">Countries</a>
                                            <ul className="big-menu clearfix">
                                                <li><a href="/countries">Countries Overview</a></li>
                                                {/* <li><a href="countries-details-5.html">United kingdom</a></li>
                                                <li><a href="countries-details.html">United States</a></li>
                                                <li><a href="countries-details-6.html">South Africa</a></li>
                                                <li><a href="countries-details-2.html">Australia</a></li>
                                                <li><a href="countries-details-7.html">India</a></li>
                                                <li><a href="countries-details-3.html">Canada</a></li>
                                                <li><a href="countries-details-8.html">The Bahams</a></li>
                                                <li><a href="countries-details-4.html">United Arab Emirates</a></li> */}
                                            </ul>
                                        </li>
                                        <li><a href="/blog">Blog</a>
                                            <ul>
                                                {/* <li><a href="blog.html">1 Column Sidebar</a></li> */}
                                                {/* <li><a href="blog-2.html">2 Columns</a></li>
                                                <li><a href="blog-3.html">3 Columns</a></li>
                                                <li><a href="blog-details.html">Single Post</a></li> */}
                                            </ul>
                                        </li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="menu-right-content">
                            {/* <div className="user-link">
                                <a href=""><i className="flaticon-user"></i></a>
                            </div> */}
                            <div className="btn-box">
                                <a href="/enquiry" className="theme-btn btn-one">Enquiry<i
                                        className="flaticon-next"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/*
        <!--sticky Header--> */}
        <div className="sticky-header">
            <div className="auto-container">
                <div className="outer-box">
                    <div className="main-box">
                        <div className="logo-box">
                            <div className="bg-layer"></div>
                            <figure className="logo"><a href="/"><img src="assets/images/logo-3.png"
                                        alt="" /></a></figure>
                        </div>
                        <div className="menu-area">
                            <nav className="main-menu clearfix">
                                {/*
                                <!--Keep This Empty / Menu will come through Javascript--> */}
                            </nav>
                        </div>
                        <div className="menu-right-content">
                            <div className="user-link">
                                <a href=""><i className="flaticon-user"></i></a>
                            </div>
                            <div className="btn-box">
                                <a href="" className="theme-btn btn-one">Enquiry<i
                                        className="flaticon-next"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    {/*
    <!-- main-header end --> */}
    {/*
    <!-- Mobile Menu  --> */}
    <div className="mobile-menu">
        <div className="menu-backdrop"></div>
        <div className="close-btn"><i className="fas fa-times"></i></div>

        <nav className="menu-box">
            <div className="nav-logo"><a href="/">
                    <img src="assets/images/logo-2.png" alt="" title="" /></a></div>
            <div className="menu-outer">
                {/*
                <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
            </div> */}
            <div className="contact-info">
                <h4>Contact Info</h4>
                <ul>
                    <li>Chicago 12, Melborne City, USA</li>
                    <li><a href="tel:+8801682648101">+88 01682648101</a></li>
                    <li><a href="mailto:info@example.com">info@example.com</a></li>
                </ul>
            </div>
            <div className="social-links">
                <ul className="clearfix">
                    <li><a href=""><span className="fab fa-twitter"></span></a></li>
                    <li><a href=""><span className="fab fa-facebook-square"></span></a></li>
                    <li><a href=""><span className="fab fa-pinterest-p"></span></a></li>
                    <li><a href=""><span className="fab fa-instagram"></span></a></li>
                    <li><a href=""><span className="fab fa-youtube"></span></a></li>
                </ul>
            </div>
    </div>
    </nav>
    </div>
    {/*
    <!-- End Mobile Menu --> */}
</>
)
}