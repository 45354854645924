import React from 'react'
import Header from './Includes/Header';
import Banner from './Banner';
import SimpleMap from './Includes/SimpleMap';
import Footer from './Includes/Footer';
export default function Home() {
    const backgroundImageUrl = 'url(assets/images/background/video-bg.jpg)'; // Replace with the actual image path

  return (
    <>
       <div class="boxed_wrapper home-2">
<Header/>

{/* <!-- preloader --> */}
{/* <div class="loader-wrap">
    <div class="preloader">
        <div class="preloader-close">x</div>
        <div id="handle-preloader" class="handle-preloader">
            <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                    <span data-text-preloader="i" class="letters-loading">
                        i
                    </span>
                    <span data-text-preloader="m" class="letters-loading">
                        m
                    </span>
                    <span data-text-preloader="m" class="letters-loading">
                        m
                    </span>
                    <span data-text-preloader="i" class="letters-loading">
                        i
                    </span>
                    <span data-text-preloader="g" class="letters-loading">
                        g
                    </span>
                    <span data-text-preloader="o" class="letters-loading">
                        o
                    </span>
                </div>
            </div>  
        </div>
    </div>
</div> */}
{/* <!-- preloader end --> */}

{/* <!-- Mobile Menu  --> */}
<div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn"><i class="fas fa-times"></i></div>
    
    <nav class="menu-box">
        <div class="nav-logo"><a href="index.html"><img src="assets/images/logo-2.png" alt="" title=""/></a></div>
        <div class="menu-outer">
            {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
            </div>
        <div class="contact-info">
            <h4>Contact Info</h4>
            <ul>
                <li>Chicago 12, Melborne City, USA</li>
                <li><a href="tel:+8801682648101">+88 01682648101</a></li>
                <li><a href="mailto:info@example.com">info@example.com</a></li>
            </ul>
        </div>
        <div class="social-links">
            <ul class="clearfix">
                <li><a href="index.html"><span class="fab fa-twitter"></span></a></li>
                <li><a href="index.html"><span class="fab fa-facebook-square"></span></a></li>
                <li><a href="index.html"><span class="fab fa-pinterest-p"></span></a></li>
                <li><a href="index.html"><span class="fab fa-instagram"></span></a></li>
                <li><a href="index.html"><span class="fab fa-youtube"></span></a></li>
            </ul>
        </div>
    </nav>
</div>
{/* <!-- End Mobile Menu --> */}


{/* <!-- banner-section --> */}
{/* <section class="banner-section banner-style-two">
    <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-9.png);"}}></div>
    <div class="banner-carousel owl-theme owl-carousel owl-dots-none">
        <div class="slide-item">
            <div class="image-layer" style={{backgroundImage:"url(assets/images/banner/banner-4.jpg)"}}></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-xl-7 col-lg-12 content-column">
                        <div class="content-box">
                            <h1>Eclati</h1>
                            <h2>a Leading Migration Agency in United States</h2>
                            <p>Denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                            <div class="btn-box">
                                <a href="index-2.html" class="theme-btn btn-one">More Details<i class="flaticon-next"></i></a>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="slide-item">
            <div class="image-layer" style={{backgroundImage:"url(assets/images/banner/banner-5.jpg)"}}></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-xl-6 col-lg-12 content-column">
                        <div class="content-box">
                            <h1>Imagine</h1>
                            <h2>a Better Future & We Make it Happen Today</h2>
                            <p>Beguiled and demoralized by the charms of pleasure of the moment blinded by desire that cannot.</p>
                            <div class="btn-box">
                                <a href="index-2.html" class="theme-btn btn-one">More Details<i class="flaticon-next"></i></a>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="slide-item">
            <div class="image-layer" style={{backgroundImage:"url(assets/images/banner/banner-6.jpg)"}}></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-xl-7 col-lg-12 content-column">
                        <div class="content-box">
                            <h1>Looking</h1>
                            <h2>To Settle In The World’s Best Countries</h2>
                            <p>Denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                            <div class="btn-box">
                                <a href="index-2.html" class="theme-btn btn-one">More Details<i class="flaticon-next"></i></a>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</section> */}
{/* <!-- banner-section end --> */}

<Banner/>


{/* <!-- service-section --> */}
<section class="service-section style-two">
    <div class="auto-container">
        <div class="sec-title centred">
            <h6>Visa Categories</h6>
            <h2>Comprehensive Immigration <br />Services</h2>
        </div>
        <div class="four-item-carousel owl-carousel owl-theme">
            <div class="service-block-two">
                <div class="inner-box">
                    <div class="content-box">
                        <div class="upper">
                            <div class="icon"><img src="assets/images/icons/icon-9.png" alt=""/></div>
                            <h4><a href="visa-details.html">Student Visa</a></h4>
                            <p>Foresee the pain & trouble all that are bound ensue.</p>
                        </div>
                        <div class="btn-box">
                            <a href="visa-details.html">Read More</a>
                        </div>
                    </div>
                    <div class="overlay-content">
                        <figure class="image"><a href="visa-details.html"><img src="assets/images/service/service-2.jpg" alt=""/></a></figure>
                        <div class="btn-box">
                            <a href="visa-details.html"><span>Read More</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-block-two">
                <div class="inner-box">
                    <div class="content-box">
                        <div class="upper">
                            <div class="icon"><img src="assets/images/icons/icon-10.png" alt=""/></div>
                            <h4><a href="visa-details-2.html">Residence Visa</a></h4>
                            <p>Desire that they can foresee trouble bound ensue.</p>
                        </div>
                        <div class="btn-box">
                            <a href="visa-details-2.html">Read More</a>
                        </div>
                    </div>
                    <div class="overlay-content">
                        <figure class="image"><a href="visa-details-2.html"><img src="assets/images/service/service-1.jpg" alt=""/></a></figure>
                        <div class="btn-box">
                            <a href="visa-details-2.html"><span>Read More</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-block-two">
                <div class="inner-box">
                    <div class="content-box">
                        <div class="upper">
                            <div class="icon"><img src="assets/images/icons/icon-11.png" alt=""/></div>
                            <h4><a href="visa-details-3.html">Business Visa</a></h4>
                            <p>Equally blamed belongs those fail in their duty.</p>
                        </div>
                        <div class="btn-box">
                            <a href="visa-details-3.html">Read More</a>
                        </div>
                    </div>
                    <div class="overlay-content">
                        <figure class="image"><a href="visa-details-3.html"><img src="assets/images/service/service-3.jpg" alt=""/></a></figure>
                        <div class="btn-box">
                            <a href="visa-details-3.html"><span>Read More</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-block-two">
                <div class="inner-box">
                    <div class="content-box">
                        <div class="upper">
                            <div class="icon"><img src="assets/images/icons/icon-12.png" alt=""/></div>
                            <h4><a href="visa-details-4.html">Tourist Visa</a></h4>
                            <p>Foresee the pain & trouble all that are bound ensue.</p>
                        </div>
                        <div class="btn-box">
                            <a href="visa-details-4.html">Read More</a>
                        </div>
                    </div>
                    <div class="overlay-content">
                        <figure class="image"><a href="visa-details-4.html"><img src="assets/images/service/service-4.jpg" alt=""/></a></figure>
                        <div class="btn-box">
                            <a href="visa-details-4.html"><span>Read More</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-block-two">
                <div class="inner-box">
                    <div class="content-box">
                        <div class="upper">
                            <div class="icon"><img src="assets/images/icons/icon-9.png" alt=""/></div>
                            <h4><a href="visa-details.html">Student Visa</a></h4>
                            <p>Foresee the pain & trouble all that are bound ensue.</p>
                        </div>
                        <div class="btn-box">
                            <a href="visa-details.html">Read More</a>
                        </div>
                    </div>
                    <div class="overlay-content">
                        <figure class="image"><a href="visa-details.html"><img src="assets/images/service/service-2.jpg" alt=""/></a></figure>
                        <div class="btn-box">
                            <a href="visa-details.html"><span>Read More</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-block-two">
                <div class="inner-box">
                    <div class="content-box">
                        <div class="upper">
                            <div class="icon"><img src="assets/images/icons/icon-10.png" alt=""/></div>
                            <h4><a href="visa-details-2.html">Residence Visa</a></h4>
                            <p>Desire that they can foresee trouble bound ensue.</p>
                        </div>
                        <div class="btn-box">
                            <a href="visa-details-2.html">Read More</a>
                        </div>
                    </div>
                    <div class="overlay-content">
                        <figure class="image"><a href="visa-details-2.html"><img src="assets/images/service/service-1.jpg" alt=""/></a></figure>
                        <div class="btn-box">
                            <a href="visa-details-2.html"><span>Read More</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-block-two">
                <div class="inner-box">
                    <div class="content-box">
                        <div class="upper">
                            <div class="icon"><img src="assets/images/icons/icon-11.png" alt=""/></div>
                            <h4><a href="visa-details-3.html">Business Visa</a></h4>
                            <p>Equally blamed belongs those fail in their duty.</p>
                        </div>
                        <div class="btn-box">
                            <a href="visa-details-3.html">Read More</a>
                        </div>
                    </div>
                    <div class="overlay-content">
                        <figure class="image"><a href="visa-details-3.html"><img src="assets/images/service/service-3.jpg" alt=""/></a></figure>
                        <div class="btn-box">
                            <a href="visa-details-3.html"><span>Read More</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-block-two">
                <div class="inner-box">
                    <div class="content-box">
                        <div class="upper">
                            <div class="icon"><img src="assets/images/icons/icon-12.png" alt=""/></div>
                            <h4><a href="visa-details-4.html">Tourist Visa</a></h4>
                            <p>Foresee the pain & trouble all that are bound ensue.</p>
                        </div>
                        <div class="btn-box">
                            <a href="visa-details-4.html">Read More</a>
                        </div>
                    </div>
                    <div class="overlay-content">
                        <figure class="image"><a href="visa-details-4.html"><img src="assets/images/service/service-4.jpg" alt=""/></a></figure>
                        <div class="btn-box">
                            <a href="visa-details-4.html"><span>Read More</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- service-section end --> */}


{/* <!-- about-style-two --> */}
<section class="about-style-two bg-color-1">
    <div class="pattern-layer shape10"></div>
    <div class="outer-container">
        <div class="bg-layer aboutside"></div>
        <div class="shape" style={{backgroundImage: "url(assets/images/shape/shape-11.png);"}}></div>
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div class="content-box">
                        <div class="sec-title light">
                            <h6>About our Agency</h6>
                            <h2>Leading Immigration Consulting Firm</h2>
                        </div>
                        <div class="text">
                            <p>Reliable Service Since 1985!...</p>
                            <p>All this mistaken idea denouncing pleasure and praising pain was born will give you complete account of the system, and expound the actuals teach-ngs of the great explorer of the truth, the master-builder human happiness.</p>
                        </div>
                        <div class="btn-box">
                            <a href="about.html" class="theme-btn btn-one">More Details<i class="flaticon-next"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                    <div class="inner-box">
                        <div class="row clearfix">
                            <div class="col-lg-6 col-md-6 col-sm-12 counter-block">
                                <div class="counter-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <h4>Offices Worldwide</h4>
                                    <div class="count-outer count-box">
                                        <span class="count-text" data-speed="1500" data-stop="25">10</span><span>+</span>
                                    </div>
                                    <p>Moment so blinded by desire.</p>
                                    <div class="link"><a href="contact.html">Locations<i class="flaticon-next"></i></a></div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 counter-block">
                                <div class="counter-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <h4>Team Members</h4>
                                    <div class="count-outer count-box">
                                        <span class="count-text" data-speed="1500" data-stop="468">50</span>
                                    </div>
                                    <p>Complete account of system.</p>
                                    <div class="link"><a href="team.html">Meet Our Team<i class="flaticon-next"></i></a></div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 counter-block">
                                <div class="counter-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <h4>Visa Processed</h4>
                                    <div class="count-outer count-box">
                                        <span class="count-text" data-speed="1500" data-stop="9.7">100</span><span></span>
                                    </div>
                                    <p>To take a all trivial example.</p>
                                    <div class="link"><a href="index-2.html">Case Studies<i class="flaticon-next"></i></a></div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 counter-block">
                                <div class="counter-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <h4>Satisfied Clients</h4>
                                    <div class="count-outer count-box">
                                        <span class="count-text" data-speed="1500" data-stop="99">100</span><span>%</span>
                                    </div>
                                    <p>Ever undertake labo exercise.</p>
                                    <div class="link"><a href="index-2.html">Testimonials<i class="flaticon-next"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- about-style-two end --> */}


{/* <!-- process-section --> */}
<section class="process-section centred">
    <div class="auto-container">
        <div class="sec-title centred">
            <h6>Our Simple Process</h6>
            <h2>Your Visa Sorted in Just 3 Super <br />Simple Steps</h2>
        </div>
        <div class="upper-box">
            <div class="shape">
                <div class="shape-1 shape12"></div>
                <div class="shape-2 shape13"></div>
            </div>
            <div class="row clearfix">
                <div class="col-lg-4 col-md-6 col-sm-12 process-block">
                    <div class="process-block-one">
                        <div class="inner-box">
                            <div class="icon-box"><img src="assets/images/icons/icon-13.png" alt=""/></div>
                            <span class="count-text">01</span>
                            <h4>Complete Online Form</h4>
                            <p>Equaly blame belongs those fail their all duty through weak shrinking.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 process-block">
                    <div class="process-block-one">
                        <div class="inner-box">
                            <div class="icon-box"><img src="assets/images/icons/icon-14.png" alt=""/></div>
                            <span class="count-text">02</span>
                            <h4>Documents & Payments</h4>
                            <p>Wise man therefore always hold these matters principle selection.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 process-block">
                    <div class="process-block-one">
                        <div class="inner-box">
                            <div class="icon-box"><img src="assets/images/icons/icon-15.png" alt=""/></div>
                            <span class="count-text">03</span>
                            <h4>Receive Your Visa</h4>
                            <p>Reject pleasure secure others greater pleasure else endures worse. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lower-box centred">
            <h3>Guiding on preparing for <a href="index-2.html">eligibility exams to visa</a> assistance.</h3>
            <div class="btn-box clearfix">
                <a href="index.html" class="theme-btn btn-one">More Details<i class="flaticon-next"></i></a>
                <a href="index.html" class="theme-btn btn-two">Get support<i class="flaticon-next"></i></a>
            </div>
        </div>
    </div>
</section>
{/* <!-- process-section end --> */}


{/* <!-- coaching-style-two --> */}
<section class="coaching-style-two">
    <div class="bg-layer parallax-bg coaching-bg" data-parallax='{"y": 100}' style={{backgroundImage: "url(assets/images/background/coaching-bg.jpg);"}}></div>
    <div class="outer-container">
        <div class="row clearfix" style={{justifyContent: "center"}}>
            <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                <div class="coaching-block-two">
                    <div class="inner-box">
                        <span class="count-text">01</span>
                        <figure class="image-box"><img src="assets/images/resource/coaching-5.jpg" alt=""/></figure>
                        <div class="text">
                            <h4><a href="/coaching-details"><span>ielts</span> Coaching</a></h4>
                            <div class="link"><a href="/coaching-details"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="overlay-text">
                            <h4><a href="/coaching-details"><span>ielts</span> Coaching</a></h4>
                            <p>Indignation and dislike men who are so all beguileds and demoralized expounds that teachings of the great.</p>
                            <div class="link"><a href="/coaching-details"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                <div class="coaching-block-two">
                    <div class="inner-box">
                        <span class="count-text">02</span>
                        <figure class="image-box"><img src="assets/images/resource/coaching-6.jpg" alt=""/></figure>
                        <div class="text">
                            <h4><a href="coaching-details-2.html"><span>toefl</span> Coaching</a></h4>
                            <div class="link"><a href="coaching-details-2.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="overlay-text">
                            <h4><a href="coaching-details-2.html"><span>toefl</span> Coaching</a></h4>
                            <p>Indignation and dislike men who are so all beguileds and demoralized expounds that teachings of the great.</p>
                            <div class="link"><a href="coaching-details-2.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                <div class="coaching-block-two">
                    <div class="inner-box">
                        <span class="count-text">02</span>
                        <figure class="image-box"><img src="assets/images/resource/coaching-7.jpg" alt=""/></figure>
                        <div class="text">
                            <h4><a href="/coaching-details"><span>pte</span> Coaching</a></h4>
                            <div class="link"><a href="/coaching-details"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="overlay-text">
                            <h4><a href="/coaching-details"><span>pte</span> Coaching</a></h4>
                            <p>Indignation and dislike men who are so all beguileds and demoralized expounds that teachings of the great.</p>
                            <div class="link"><a href="/coaching-details"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                <div class="coaching-block-two">
                    <div class="inner-box">
                        <span class="count-text">04</span>
                        <figure class="image-box"><img src="assets/images/resource/coaching-8.jpg" alt=""/></figure>
                        <div class="text">
                            <h4><a href="coaching-details-4.html"><span>oet</span> Coaching</a></h4>
                            <div class="link"><a href="coaching-details-4.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="overlay-text">
                            <h4><a href="coaching-details-4.html"><span>pte</span> Coaching</a></h4>
                            <p>Indignation and dislike men who are so all beguileds and demoralized expounds that teachings of the great.</p>
                            <div class="link"><a href="coaching-details-4.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                <div class="coaching-block-two">
                    <div class="inner-box">
                        <span class="count-text">05</span>
                        <figure class="image-box"><img src="assets/images/resource/coaching-9.jpg" alt=""/></figure>
                        <div class="text">
                            <h4><a href="coaching-details-5.html"><span>Gre</span> Coaching</a></h4>
                            <div class="link"><a href="coaching-details-5.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="overlay-text">
                            <h4><a href="coaching-details-5.html"><span>Gre</span> Coaching</a></h4>
                            <p>Indignation and dislike men who are so all beguileds and demoralized expounds that teachings of the great.</p>
                            <div class="link"><a href="coaching-details-5.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                <div class="coaching-block-two">
                    <div class="inner-box">
                        <span class="count-text">06</span>
                        <figure class="image-box"><img src="assets/images/resource/coaching-10.jpg" alt=""/></figure>
                        <div class="text">
                            <h4><a href="coaching-details-6.html"><span>Gmat</span> Coaching</a></h4>
                            <div class="link"><a href="coaching-details-6.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="overlay-text">
                            <h4><a href="coaching-details-6.html"><span>Gmat</span> Coaching</a></h4>
                            <p>Indignation and dislike men who are so all beguileds and demoralized expounds that teachings of the great.</p>
                            <div class="link"><a href="coaching-details-6.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                <div class="coaching-block-two">
                    <div class="inner-box">
                        <span class="count-text">07</span>
                        <figure class="image-box"><img src="assets/images/resource/coaching-11.jpg" alt=""/></figure>
                        <div class="text">
                            <h4><a href="coaching-details-7.html"><span>Sat</span> Coaching</a></h4>
                            <div class="link"><a href="coaching-details-7.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="overlay-text">
                            <h4><a href="coaching-details-7.html"><span>Sat</span> Coaching</a></h4>
                            <p>Indignation and dislike men who are so all beguileds and demoralized expounds that teachings of the great.</p>
                            <div class="link"><a href="coaching-details-7.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                <div class="coaching-block-two">
                    <div class="inner-box">
                        <span class="count-text">08</span>
                        <figure class="image-box"><img src="assets/images/resource/coaching-12.jpg" alt=""/></figure>
                        <div class="text">
                            <h4><a href="coaching-details-8.html"><span>ine</span> Coaching</a></h4>
                            <div class="link"><a href="coaching-details-8.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                        <div class="overlay-text">
                            <h4><a href="coaching-details-8.html"><span>ine</span> Coaching</a></h4>
                            <p>Indignation and dislike men who are so all beguileds and demoralized expounds that teachings of the great.</p>
                            <div class="link"><a href="coaching-details-8.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
</section>
{/* <!-- coaching-style-two end --> */}


{/* <!-- statistics-section --> */}
<section class="statistics-section">
    <div class="auto-container">
        <div class="row clearfix">
            <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <div class="image-box">
                    <figure class="image"><img src="assets/images/resource/statistics-1.jpg" alt=""/></figure>
                    <figure class="image image-2"><img src="assets/images/resource/statistics-1.png" alt=""/></figure>
                    <div class="curve-text">
                        <div class="icon-box"><img src="assets/images/icons/icon-16.png" alt=""/></div>
                        <span class="curved-circle">Eclati Imigration Agency Esytablished 1985 . </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                    <div class="sec-title">
                        <h6>Company Reports & Statistics</h6>
                        <h2>The Impact of <br />Our Competitive Efforts</h2>
                        <p>Denouncing pleasure and praising pain was born and will give you complete great explorer of the truth the master-builder.</p>
                    </div>
                    <div class="progress-inner">
                        <div class="progress-box">
                            <div class="bar-box">
                                <h5>Student Visa</h5>
                                <div class="bar">
                                    <div class="bar-inner count-bar" data-percent="78%"></div>
                                </div>
                                <div class="count-text">78%</div>
                            </div>
                        </div>
                        <div class="progress-box">
                            <div class="bar-box">
                                <h5>Residence Visa</h5>
                                <div class="bar">
                                    <div class="bar-inner count-bar" data-percent="92%"></div>
                                </div>
                                <div class="count-text">92%</div>
                            </div>
                        </div>
                        <div class="progress-box">
                            <div class="bar-box">
                                <h5>Business Visa</h5>
                                <div class="bar">
                                    <div class="bar-inner count-bar" data-percent="65%"></div>
                                </div>
                                <div class="count-text">65%</div>
                            </div>
                        </div>
                        <div class="progress-box">
                            <div class="bar-box">
                                <h5>Tourist Visa</h5>
                                <div class="bar">
                                    <div class="bar-inner count-bar" data-percent="86%"></div>
                                </div>
                                <div class="count-text">86%</div>
                            </div>
                        </div>
                    </div>
                    <div class="download-box">
                        <div class="icon"><i class="flaticon-pdf"></i></div>
                        <h6><a href="index-2.html">Download</a></h6>
                        <h5>Report for the Year 2020</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- statistics-section end --> */}


{/* <!-- countries-style-two --> */}
<section class="countries-style-two bg-color-1">
    <div class="auto-container">
        <div class="sec-title centred">
            <h6>Countries We Offer</h6>
            <h2>Immigration & Visa Services <br />Following Countries</h2>
        </div>
        <div class="tabs-box">
            <div class="tab-btn-box">
                <ul class="tab-btns tab-buttons centred clearfix">
                    <li class="tab-btn active-btn" data-tab="#tab-1"><div class="icon-box"><i class="flaticon-open-book"></i></div>Education</li>
                    <li class="tab-btn" data-tab="#tab-2"><div class="icon-box"><i class="flaticon-immigrant"></i></div>Immigration</li>
                    <li class="tab-btn" data-tab="#tab-3"><div class="icon-box"><i class="flaticon-suitcase"></i></div>Business</li>
                    <li class="tab-btn" data-tab="#tab-4"><div class="icon-box"><i class="flaticon-camera"></i></div>Tourist</li>
                </ul>
            </div>
            <div class="tabs-content">
                <div class="tab active-tab" id="tab-1">
                    <div class="row clearfix">
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-2.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">United States</a></h4>
                                        <p>50 of the top 100 Universities...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-2.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-3.png" alt=""/></figure>
                                        <h4><a href="countries-details-2.html">Australia</a></h4>
                                        <p>Known for the quality universiti...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-3.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-4.png" alt=""/></figure>
                                        <h4><a href="countries-details-3.html">Canada</a></h4>
                                        <p>Well recognized education syste...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-4.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-5.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Scotland</a></h4>
                                        <p>Moment so blinded desires that...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-5.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-6.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Netherland</a></h4>
                                        <p>Well recognized education syste...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-6.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-7.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Germany</a></h4>
                                        <p>Konown for the quality universiti...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-7.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab" id="tab-2">
                    <div class="row clearfix">
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-2.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">United States</a></h4>
                                        <p>50 of the top 100 Universities...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-2.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-3.png" alt=""/></figure>
                                        <h4><a href="countries-details-2.html">Australia</a></h4>
                                        <p>Known for the quality universiti...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-3.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-4.png" alt=""/></figure>
                                        <h4><a href="countries-details-3.html">Canada</a></h4>
                                        <p>Well recognized education syste...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-4.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-5.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Scotland</a></h4>
                                        <p>Moment so blinded desires that...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-5.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-6.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Netherland</a></h4>
                                        <p>Well recognized education syste...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-6.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-7.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Germany</a></h4>
                                        <p>Konown for the quality universiti...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-7.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab" id="tab-3">
                    <div class="row clearfix">
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-2.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">United States</a></h4>
                                        <p>50 of the top 100 Universities...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-2.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-3.png" alt=""/></figure>
                                        <h4><a href="countries-details-2.html">Australia</a></h4>
                                        <p>Known for the quality universiti...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-3.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-4.png" alt=""/></figure>
                                        <h4><a href="countries-details-3.html">Canada</a></h4>
                                        <p>Well recognized education syste...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-4.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-5.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Scotland</a></h4>
                                        <p>Moment so blinded desires that...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-5.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-6.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Netherland</a></h4>
                                        <p>Well recognized education syste...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-6.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-7.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Germany</a></h4>
                                        <p>Konown for the quality universiti...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-7.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab" id="tab-4">
                    <div class="row clearfix">
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-2.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">United States</a></h4>
                                        <p>50 of the top 100 Universities...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-2.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-3.png" alt=""/></figure>
                                        <h4><a href="countries-details-2.html">Australia</a></h4>
                                        <p>Known for the quality universiti...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-3.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-4.png" alt=""/></figure>
                                        <h4><a href="countries-details-3.html">Canada</a></h4>
                                        <p>Well recognized education syste...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-4.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-5.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Scotland</a></h4>
                                        <p>Moment so blinded desires that...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-5.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-6.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Netherland</a></h4>
                                        <p>Well recognized education syste...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-6.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 countries-block">
                            <div class="countries-block-two">
                                <div class="inner-box">
                                    <div class="inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-7.png" alt=""/></figure>
                                        <h4><a href="countries-details.html">Germany</a></h4>
                                        <p>Konown for the quality universiti...</p>
                                    </div>
                                    <div class="overlay-inner">
                                        <figure class="flag"><img src="assets/images/icons/flag-7.png" alt=""/></figure>
                                        <p>Known for the quality universities & friendly country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="more-link centred">
            <h6><a href="countries.html">View All Countries<i class="flaticon-next"></i></a></h6>
        </div>
    </div>
</section>
{/* <!-- countries-style-two end --> */}


{/* <!-- chooseus-style-two --> */}
<section class="chooseus-style-two bg-color-1">
    <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-14.png);"}}></div>
    <figure class="image-layer"><img src="assets/images/resource/chooseus-1.png" alt=""/></figure>
    <div class="auto-container">
        <div class="row clearfix">
            <div class="col-xl-9 col-lg-12 col-md-12 content-column offset-xl-3">
                <div class="content-box">
                    <div class="sec-title">
                        <h6>Why Choose Us</h6>
                        <h2>Reason for Choosing Us</h2>
                        <p>Denouncing pleasure and praising pain was born and will give you completed great <br />explorer of the  truth the master-builder.</p>
                    </div>
                    <div class="inner-box">
                        <div class="row clearfix">
                            <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="icon-box"><img src="assets/images/icons/icon-17.png" alt=""/></div>
                                    <h4>Direct Interviews</h4>
                                    <p>Expound actual teachings too the great explorers truth.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="icon-box"><img src="assets/images/icons/icon-18.png" alt=""/></div>
                                    <h4>Cost-Effective</h4>
                                    <p>The same as saying shrinking from  we like every best.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="icon-box"><img src="assets/images/icons/icon-19.png" alt=""/></div>
                                    <h4>Faster Processing</h4>
                                    <p>The same as saying shrinking from  we like every best.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="icon-box"><img src="assets/images/icons/icon-20.png" alt=""/></div>
                                    <h4>Trusted by Clients</h4>
                                    <p>Give you a completed account the expound the teachings.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="icon-box"><img src="assets/images/icons/icon-21.png" alt=""/></div>
                                    <h4>Visa Assistance</h4>
                                    <p>Give you a completed account the expound the teachings.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="icon-box"><img src="assets/images/icons/icon-22.png" alt=""/></div>
                                    <h4>24/7 Support</h4>
                                    <p>Expound actual teachings too the great explorers truth.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- chooseus-style-two end --> */}


{/* <!-- team-section --> */}
<section class="team-section centred">
    {/* <div class="auto-container">
        <div class="sec-title centred">
            <h6>Expert Team Members</h6>
            <h2>Our Team at Your Service</h2>
        </div>
        <div class="row clearfix">
            <div class="col-lg-3 col-md-6 col-sm-12 team-block">
                <div class="team-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="image-box">
                            <figure class="image"><img src="assets/images/team/team-4.jpg" alt=""/></figure>
                            <ul class="list clearfix">
                                <li class="share-option">
                                    <span class="share-icon"><i class="flaticon-share"></i></span>
                                    <ul class="share-links clearfix">
                                        <li><a href="index-3.html"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                                    </ul>
                                </li>
                                <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                            </ul>
                        </div>
                        <div class="lower-content">
                            <h4><a href="index.html">Reggie Hunter</a></h4>
                            <span class="designation">Founder & Managing Partner</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 team-block">
                <div class="team-block-one wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="image-box">
                            <figure class="image"><img src="assets/images/team/team-5.jpg" alt=""/></figure>
                            <ul class="list clearfix">
                                <li class="share-option">
                                    <span class="share-icon"><i class="flaticon-share"></i></span>
                                    <ul class="share-links clearfix">
                                        <li><a href="index-3.html"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                                    </ul>
                                </li>
                                <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                            </ul>
                        </div>
                        <div class="lower-content">
                            <h4><a href="index.html">Roman Frederick</a></h4>
                            <span class="designation">Regional Manager, Operations</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 team-block">
                <div class="team-block-one wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="image-box">
                            <figure class="image"><img src="assets/images/team/team-6.jpg" alt=""/></figure>
                            <ul class="list clearfix">
                                <li class="share-option">
                                    <span class="share-icon"><i class="flaticon-share"></i></span>
                                    <ul class="share-links clearfix">
                                        <li><a href="index-3.html"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                                    </ul>
                                </li>
                                <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                            </ul>
                        </div>
                        <div class="lower-content">
                            <h4><a href="index.html">Ruby Valentina</a></h4>
                            <span class="designation">Immigration Consultants</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 team-block">
                <div class="team-block-one wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="image-box">
                            <figure class="image"><img src="assets/images/team/team-7.jpg" alt=""/></figure>
                            <ul class="list clearfix">
                                <li class="share-option">
                                    <span class="share-icon"><i class="flaticon-share"></i></span>
                                    <ul class="share-links clearfix">
                                        <li><a href="index-3.html"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                                    </ul>
                                </li>
                                <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                            </ul>
                        </div>
                        <div class="lower-content">
                            <h4><a href="index.html">Theodore Hudson</a></h4>
                            <span class="designation">Consultants</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
</section>
{/* <!-- team-section end --> */}


{/* <!-- video-section --> */}
<section class="video-section centred">
    <div class="bg-layer"></div>
    <div class="auto-container">
        <div class="video-inner clearfix" style={{backgroundImage: "url(assets/images/background/video-bg.jpg);"}}>
            <div class="video-btn">
                <a href="https://www.youtube.com/watch?v=nfP5N9Yc72A&amp;t=28s" class="lightbox-image video-btn" data-caption=""><i class="flaticon-play-arrow"></i></a>
            </div>
            <div class="text-box pull-right">
                <h2>2 Minutes <br />Visa Process Video</h2>
                <p>Idea of denouncing pleasure & praising pain was born to all.</p>
            </div>
        </div>
    </div>
</section>
{/* <!-- video-section end --> */}


{/* <!-- testimonial-style-two --> */}
<section class="testimonial-style-two">
    <div class="pattern-layer carousel"></div>
    <div class="thumb-inner">
        <figure class="thumb thumb-1"><img src="assets/images/resource/testimonial-4.jpg" alt=""/></figure>
        <figure class="thumb thumb-2"><img src="assets/images/resource/testimonial-5.jpg" alt=""/></figure>
        <figure class="thumb thumb-3"><img src="assets/images/resource/testimonial-6.jpg" alt=""/></figure>
        <figure class="thumb thumb-4"><img src="assets/images/resource/testimonial-7.jpg" alt=""/></figure>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <div class="auto-container">
        <div class="inner-container">
            {/* <figure class="quote"><img src="assets/images/icons/quote-2.png" alt=""/></figure> */}
            <div class="single-item-carousel owl-carousel owl-theme owl-dots-none">
                <div class="testimonial-content">
                    <div class="inner-box">
                        <figure class="thumb-box"><img src="assets/images/resource/testimonial-4.jpg" alt=""/></figure>
                        <ul class="rating clearfix">
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                        </ul>
                        <h3>Smooth & Efficient Service!...</h3>
                        <p>Eclati Consultancy is definitely a highly recommended canadian an migration agency. A big applause & very grateful to Mr.Richardson for efforts and assistance.</p>
                        <div class="author-box">
                            <h3>Silverster Scott</h3>
                            <span class="designation">Netherland</span>
                        </div>
                    </div>
                </div>
                <div class="testimonial-content">
                    <div class="inner-box">
                        <figure class="thumb-box"><img src="assets/images/resource/testimonial-5.jpg" alt=""/></figure>
                        <ul class="rating clearfix">
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                        </ul>
                        <h3>Smooth & Efficient Service!...</h3>
                        <p>Eclati Consultancy is definitely a highly recommended canadian an migration agency. A big applause & very grateful to Mr.Richardson for efforts and assistance.</p>
                        <div class="author-box">
                            <h3>Silverster Scott</h3>
                            <span class="designation">Netherland</span>
                        </div>
                    </div>
                </div>
                <div class="testimonial-content">
                    <div class="inner-box">
                        <figure class="thumb-box"><img src="assets/images/resource/testimonial-6.jpg" alt=""/></figure>
                        <ul class="rating clearfix">
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                        </ul>
                        <h3>Smooth & Efficient Service!...</h3>
                        <p>Eclati Consultancy is definitely a highly recommended canadian an migration agency. A big applause & very grateful to Mr.Richardson for efforts and assistance.</p>
                        <div class="author-box">
                            <h3>Silverster Scott</h3>
                            <span class="designation">Netherland</span>
                        </div>
                    </div>
                </div>
                <div class="testimonial-content">
                    <div class="inner-box">
                        <figure class="thumb-box"><img src="assets/images/resource/testimonial-7.jpg" alt=""/></figure>
                        <ul class="rating clearfix">
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                        </ul>
                        <h3>Smooth & Efficient Service!...</h3>
                        <p>Eclati Consultancy is definitely a highly recommended canadian an migration agency. A big applause & very grateful to Mr.Richardson for efforts and assistance.</p>
                        <div class="author-box">
                            <h3>Silverster Scott</h3>
                            <span class="designation">Netherland</span>
                        </div>
                    </div>
                </div>
                <div class="testimonial-content">
                    <div class="inner-box">
                        <figure class="thumb-box"><img src="assets/images/resource/testimonial-8.jpg" alt=""/></figure>
                        <ul class="rating clearfix">
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                            <li><i class="flaticon-star"></i></li>
                        </ul>
                        {/* <h3>Smooth & Efficient Service!...</h3>
                        <p>Eclati Consultancy is definitely a highly recommended canadian an migration agency. A big applause & very grateful to Mr.Richardson for efforts and assistance.</p>
                        <div class="author-box">
                            <h3>Silverster Scott</h3>
                            <span class="designation">Netherland</span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- testimonial-style-two end --> */}


{/* <!-- news-section --> */}
{/* <section class="news-section style-two">
    <div class="auto-container">
        <div class="sec-title centred">
            <h6>News & Updates</h6>
            <h2>Read Our Latest Insights</h2>
        </div>
        <div class="single-item-carousel owl-carousel owl-theme owl-dots-none">
            <div class="news-block-one">
                <div class="inner-box">
                    <div class="image-box">
                        <h2>14<span>Oct</span></h2>
                        <figure class="image"><a href="blog-details.html"><img src="assets/images/news/news-4.jpg" alt=""/></a></figure>
                    </div>
                    <div class="lower-content">
                        <div class="author-box">
                            <figure class="author-thumb"><img src="assets/images/news/author-1.jpg" alt=""/></figure>
                            <h6><a href="blog-details.html">Immigration</a></h6>
                            <span>Post By: Colmin O'Neill</span>
                        </div>
                        <h4><a href="blog-details.html">Citizenship Concept on How to Become a UK Citizen</a></h4>
                        <p>Laborious physical exercise excepts obtain some advantage from...</p>
                        <div class="lower-box clearfix">
                            <div class="link pull-left"><a href="blog-details.html"><span>REad More</span></a></div>
                            <ul class="info clearfix pull-right">
                                <li><i class="far fa-heart"></i><a href="blog-details.html">36</a></li>
                                <li><i class="far fa-comment"></i><a href="blog-details.html">08</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="news-block-one">
                <div class="inner-box">
                    <div class="image-box">
                        <h2>12<span>Oct</span></h2>
                        <figure class="image"><a href="blog-details.html"><img src="assets/images/news/news-5.jpg" alt=""/></a></figure>
                    </div>
                    <div class="lower-content">
                        <div class="author-box">
                            <figure class="author-thumb"><img src="assets/images/news/author-2.jpg" alt=""/></figure>
                            <h6><a href="blog-details.html">Resident Visa</a></h6>
                            <span>Post By: Layla Madison</span>
                        </div>
                        <h4><a href="blog-details.html">7 Benefits of Being a Permanent Resident in Canada</a></h4>
                        <p>Foresee the pain and trouble that are bound ensue equal blame belongs...</p>
                        <div class="lower-box clearfix">
                            <div class="link pull-left"><a href="blog-details.html"><span>REad More</span></a></div>
                            <ul class="info clearfix pull-right">
                                <li><i class="far fa-heart"></i><a href="blog-details.html">03</a></li>
                                <li><i class="far fa-comment"></i><a href="blog-details.html">12</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="news-block-one">
                <div class="inner-box">
                    <div class="image-box">
                        <h2>06<span>Oct</span></h2>
                        <figure class="image"><a href="blog-details.html"><img src="assets/images/news/news-6.jpg" alt=""/></a></figure>
                    </div>
                    <div class="lower-content">
                        <div class="author-box">
                            <figure class="author-thumb"><img src="assets/images/news/author-3.jpg" alt=""/></figure>
                            <h6><a href="blog-details.html">News & Tips</a></h6>
                            <span>Post By: Colmin O'Neill</span>
                        </div>
                        <h4><a href="blog-details.html">Three Common Mistakes in Visa Applications</a></h4>
                        <p>Laborious physical exercise excepts obtain some advantage from...</p>
                        <div class="lower-box clearfix">
                            <div class="link pull-left"><a href="blog-details.html"><span>REad More</span></a></div>
                            <ul class="info clearfix pull-right">
                                <li><i class="far fa-heart"></i><a href="blog-details.html">15</a></li>
                                <li><i class="far fa-comment"></i><a href="blog-details.html">06</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}
{/* <!-- news-section end --> */}


{/* <!-- callback-section --> */}
<section class="callback-section">
    <div class="outer-container">
        <div class="bg-layer mapside"></div>
        <div class="map-inner">
            <SimpleMap/>
            {/* <div 
                class="google-map" 
                id="contact-google-map" 
                data-map-lat="40.712776" 
                data-map-lng="-74.005974"  
                data-map-title="Brooklyn, New York, United Kingdom" 
                data-map-zoom="12" 
                data-markers='{
                    "marker-1": [40.712776, -74.005974, "<h4>Branch Office</h4><p>77/99 New York</p>","assets/images/icons/map-marker.png"]
                }'>

            </div> */}
            {/* <div class="map-location">
                <div class="single-location active">
                    <div class="location-pin"><img src="assets/images/icons/map-marker.png" alt=""/></div>
                    <div class="address-box">
                        <h4>Brooklyn Office</h4>
                        <ul class="info clearfix">
                            <li>P. B: 222. Mid Corner Street, 11201</li>
                            <li><a href="tel:22462063090011">+224 620 63 09 00 & 11</a></li>
                            <li><a href="mailto:supportyou@info.com">supportyou@info.com</a></li>
                        </ul>
                    </div>
                </div>
                <div class="single-location">
                    <div class="location-pin"><img src="assets/images/icons/map-marker.png" alt=""/></div>
                    <div class="address-box">
                        <h4>New York Office</h4>
                        <ul class="info clearfix">
                            <li>P. B: 222. Mid Corner Street, 11201</li>
                            <li><a href="tel:22462063090011">+224 620 63 09 00 & 11</a></li>
                            <li><a href="mailto:supportyou@info.com">supportyou@info.com</a></li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </div>
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div class="content-box">
                        <div class="sec-title light">
                            <h6>Let’s Connect</h6>
                            <h2>Get a Call From Experts</h2>
                            <p>Feel free to call at our toll free 1800-100-6700 or request for a call back.</p>
                        </div>
                        <div class="form-inner">
                            <form action="index-2.html" method="post">
                                <div class="row clearfix">
                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="text" name="name" placeholder="Your Name" required/>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="email" name="email" placeholder="Email Addres" required/>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <input type="text" name="phone" placeholder="Phone Number" required/>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <div class="message-btn">
                                            <button type="submit" class="theme-btn btn-two">Submit Now<i class="flaticon-next"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- callback-section end --> */}


<Footer/>
</div>
    </>
  )
}
