import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './Components/About';
import Coaching from './Components/Coaching';
import Visa from './Components/Visa';
import Countries from './Components/Countries';
import Blog from './Components/Blog';
import Contact from './Components/Contact';
import Enquiry from './Components/Enquiry';
import VisaDetails4 from './Components/VisaDetails4';
import VisaDetails3 from './Components/VisaDetails3';
import VisaDetails2 from './Components/VisaDetails2';
import VisaDetails from './Components/VisaDetails';

function App() {
return (
<div className="App">
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/coaching" element={<Coaching />} />
      <Route path="/visa" element={<Visa />} />
      <Route path="/countries" element={<Countries />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/enquiry" element={<Enquiry />} />
      <Route path="/visa-details-4" element={<VisaDetails4 />} />
      <Route path="/visa-details-3" element={<VisaDetails3 />} />
      <Route path="/visa-details-2" element={<VisaDetails2 />} />
      <Route path="/visa-details" element={<VisaDetails />} />
    </Routes>
  </BrowserRouter>
</div>
);
}

export default App;