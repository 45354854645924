import React from 'react'
import Header from './Includes/Header'
import './About.css'
import Footer from './Includes/Footer'
export default function About() {
  return (
    <>
    <Header/>
    
    <div class="boxed_wrapper about">
    {/* <!-- Page Title --> */}
        <section class="page-title page-title">
            <div class="auto-container">
                <div class="content-box clearfix">
                    <div class="title centred">
                        <h6>About Eclati</h6>
                        <h1>Trusted Immigration Consultant </h1>
                    </div>
                    <ul class="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About</a></li>
                        <li>About Eclati</li>
                    </ul>
                </div>
            </div>
        </section>
        {/* <!-- End Page Title --> */}


        
        {/* <!-- about-style-three --> */}
        {/* <section class="about-style-three about-page">
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                        <div class="image-box">
                            <div class="text">
                                <h2>14</h2>
                                <h6>Years of <br />Experienced</h6>
                            </div>
                            <div class="curve-text">
                                <div class="icon-box"><img src="assets/images/icons/icon-34.png" alt=""/></div>
                            </div>
                            <figure class="image image-1"><img src="assets/images/resource/about-4.jpg" alt=""/></figure>
                            <figure class="image image-2"><img src="assets/images/resource/about-5.jpg" alt=""/></figure>
                            <figure class="image image-3"><img src="assets/images/resource/about-6.jpg" alt=""/></figure>
                        </div>  
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="content_block_one">
                            <div class="content-box">
                                <div class="sec-title">
                                    <h6>About Eclati</h6>
                                    <h2>Leading Immigration Consulting Firm Based In United States.</h2>
                                </div>
                                <div class="text">
                                    <p>Reliable Service Since 2007!...</p>
                                    <p>All this mistaken idea of denouncing pleasure and praising pain was born and will give you a complete account of the system, and expound the actual teach- ings of the great explorer of the truth, the master-builder of human happiness. No one rejects dislikes or avoids pleasures itself because it is all pleasure but because those who do not know how pursue those who fail.</p>
                                </div>
                                <div class="author-box">
                                    <figure class="author-thumb"><img src="assets/images/resource/author-1.jpg" alt=""/></figure>
                                    <h4>Reggie Hunter</h4>
                                    <span class="designation">Founder</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!-- about-style-three end --> */}



        {/* <!-- mission-section --> */}
        {/* <section class="mission-section mission-bg">
            <div class="auto-container">
                <div class="inner-container mission-bg2">
                    <div class="tabs-box">
                        <div class="row clearfix">
                            <div class="col-lg-6 col-md-12 col-sm-12 left-column">
                                <div class="left-content centred">
                                    <div class="video-inner">
                                        <a href="https://www.youtube.com/watch?v=nfP5N9Yc72A&amp;t=28s" class="lightbox-image video-btn" data-caption=""><i class="flaticon-play-arrow"></i></a>
                                    </div>
                                    <div class="tab-btn-box">
                                        <ul class="tab-btns tab-buttons clearfix">
                                            <li class="tab-btn active-btn" data-tab="#tab-1"><h4><i class="flaticon-target"></i>Mission</h4></li>
                                            <li class="tab-btn" data-tab="#tab-2"><h4><i class="flaticon-vision"></i>Vision</h4></li>
                                            <li class="tab-btn" data-tab="#tab-3"><h4><i class="flaticon-gems"></i>Values</h4></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 right-column">
                                <div class="tabs-content">
                                    <div class="tab active-tab" id="tab-1">
                                        <div class="inner-box">
                                            <div class="icon-box"><i class="flaticon-target"></i></div>
                                            <h6>Mission Statements</h6>
                                            <h3>Bring Inspiration & Innovation</h3>
                                            <p>Denouncing pleasure and praising pain was born will give you complete account of the system expound the actual teachings of the great explorer the truth master builder of human happiness.</p>
                                        </div>
                                    </div>
                                    <div class="tab" id="tab-2">
                                        <div class="inner-box">
                                            <div class="icon-box"><i class="flaticon-vision"></i></div>
                                            <h6>Vision Statements</h6>
                                            <h3>Bring Inspiration & Innovation</h3>
                                            <p>Denouncing pleasure and praising pain was born will give you complete account of the system expound the actual teachings of the great explorer the truth master builder of human happiness.</p>
                                        </div>
                                    </div>
                                    <div class="tab" id="tab-3">
                                        <div class="inner-box">
                                            <div class="icon-box"><i class="flaticon-gems"></i></div>
                                            <h6>Values Statements</h6>
                                            <h3>Bring Inspiration & Innovation</h3>
                                            <p>Denouncing pleasure and praising pain was born will give you complete account of the system expound the actual teachings of the great explorer the truth master builder of human happiness.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!-- mission-section end --> */}


        {/* <!-- team-section --> */}
        <section class="team-section centred bg-color-1">
            {/* <div class="auto-container">
                <div class="inner-container">
                    <div class="sec-title centred">
                        <h6>Expert Team Members</h6>
                        <h2>Our Team at Your Service</h2>
                    </div>
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div class="team-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <div class="image-box">
                                        <figure class="image"><img src="assets/images/team/team-4.jpg" alt=""/></figure>
                                        <ul class="list clearfix">
                                            <li class="share-option">
                                                <span class="share-icon"><i class="flaticon-share"></i></span>
                                                <ul class="share-links clearfix">
                                                    <li><a href="index-3.html"><i class="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                                                    <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                                                </ul>
                                            </li>
                                            <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="lower-content">
                                        <h4><a href="index.html">Reggie Hunter</a></h4>
                                        <span class="designation">Founder & Managing Partner</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div class="team-block-one wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <div class="image-box">
                                        <figure class="image"><img src="assets/images/team/team-5.jpg" alt=""/></figure>
                                        <ul class="list clearfix">
                                            <li class="share-option">
                                                <span class="share-icon"><i class="flaticon-share"></i></span>
                                                <ul class="share-links clearfix">
                                                    <li><a href="index-3.html"><i class="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                                                    <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                                                </ul>
                                            </li>
                                            <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="lower-content">
                                        <h4><a href="index.html">Roman Frederick</a></h4>
                                        <span class="designation">Regional Manager, Operations</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div class="team-block-one wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <div class="image-box">
                                        <figure class="image"><img src="assets/images/team/team-6.jpg" alt=""/></figure>
                                        <ul class="list clearfix">
                                            <li class="share-option">
                                                <span class="share-icon"><i class="flaticon-share"></i></span>
                                                <ul class="share-links clearfix">
                                                    <li><a href="index-3.html"><i class="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                                                    <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                                                </ul>
                                            </li>
                                            <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="lower-content">
                                        <h4><a href="index.html">Ruby Valentina</a></h4>
                                        <span class="designation">Immigration Consultants</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div class="team-block-one wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <div class="image-box">
                                        <figure class="image"><img src="assets/images/team/team-7.jpg" alt=""/></figure>
                                        <ul class="list clearfix">
                                            <li class="share-option">
                                                <span class="share-icon"><i class="flaticon-share"></i></span>
                                                <ul class="share-links clearfix">
                                                    <li><a href="index-3.html"><i class="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                                                    <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                                                </ul>
                                            </li>
                                            <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="lower-content">
                                        <h4><a href="index.html">Theodore Hudson</a></h4>
                                        <span class="designation">Consultants</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="more-text">
                        <h6><a href="team.html">View All Members<i class="flaticon-next"></i></a></h6>
                    </div>
                </div>
            </div> */}
        </section>
        {/* <!-- team-section end --> */}

        {/* <!-- chooseus-style-two --> */}
        <section class="chooseus-style-two bg-color-1">
            <div class="pattern-layer" style={{backgroundmage: "url(assets/images/shape/shape-14.png);"}}></div>
            <figure class="image-layer"><img src="assets/images/resource/chooseus-1.png" alt=""/></figure>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-xl-9 col-lg-12 col-md-12 content-column offset-xl-3">
                        <div class="content-box">
                            <div class="sec-title">
                                <h6>Why Choose Us</h6>
                                <h2>Reason for Choosing Us</h2>
                                <p>Denouncing pleasure and praising pain was born and will give you completed great <br />explorer of the  truth the master-builder.</p>
                            </div>
                            <div class="inner-box">
                                <div class="row clearfix">
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                            <div class="icon-box"><img src="assets/images/icons/icon-17.png" alt=""/></div>
                                            <h4>Direct Interviews</h4>
                                            <p>Expound actual teachings too the great explorers truth.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                            <div class="icon-box"><img src="assets/images/icons/icon-18.png" alt=""/></div>
                                            <h4>Cost-Effective</h4>
                                            <p>The same as saying shrinking from  we like every best.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                            <div class="icon-box"><img src="assets/images/icons/icon-19.png" alt=""/></div>
                                            <h4>Faster Processing</h4>
                                            <p>The same as saying shrinking from  we like every best.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                            <div class="icon-box"><img src="assets/images/icons/icon-20.png" alt=""/></div>
                                            <h4>Trusted by Clients</h4>
                                            <p>Give you a completed account the expound the teachings.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                            <div class="icon-box"><img src="assets/images/icons/icon-21.png" alt=""/></div>
                                            <h4>Visa Assistance</h4>
                                            <p>Give you a completed account the expound the teachings.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                        <div class="single-item wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                            <div class="icon-box"><img src="assets/images/icons/icon-22.png" alt=""/></div>
                                            <h4>24/7 Support</h4>
                                            <p>Expound actual teachings too the great explorers truth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- chooseus-style-two end --> */}

        {/* <!-- funfact-section --> */}
        <section class="funfact-section alternat-2">
            <div class="outer-container clearfix">
                <div class="funfact-block-one">
                    <div class="inner-box">
                        <div class="inner">
                            <div class="icon-box"><img src="assets/images/icons/icon-41.png" alt=""/></div>
                            <div class="count-outer count-box">
                                <span class="count-text" data-speed="1500" data-stop="25">10</span><span>+</span>
                            </div>
                        </div>
                        <h4>Offices Worldwide</h4>
                        <p>This mistaken idea denouncing praising pain was born anyone.</p>
                    </div>
                </div>
                <div class="funfact-block-one">
                    <div class="inner-box">
                        <div class="inner">
                            <div class="icon-box"><img src="assets/images/icons/icon-42.png" alt=""/></div>
                            <div class="count-outer count-box">
                                <span class="count-text" data-speed="1500" data-stop="468">50</span>
                            </div>
                        </div>
                        <h4>Team Members</h4>
                        <p>Teachings of the great explorer the truth master-builder of human.</p>
                    </div>
                </div>
                <div class="funfact-block-one">
                    <div class="inner-box">
                        <div class="inner">
                            <div class="icon-box"><img src="assets/images/icons/icon-43.png" alt=""/></div>
                            <div class="count-outer count-box">
                                <span class="count-text" data-speed="1500" data-stop="9.7">100</span><span></span>
                            </div>
                        </div>
                        <h4>Visa Processed</h4>
                        <p>Nor again is there anyone loves or pursue or desires to obtain pain.</p>
                    </div>
                </div>
                <div class="funfact-block-one">
                    <div class="inner-box">
                        <div class="inner">
                            <div class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""/></div>
                            <div class="count-outer count-box">
                               <span class="count-text" data-speed="1500" data-stop="5">10</span>
                            </div>
                        </div>
                        <h4>Countries Served</h4>
                        <p>This mistaken idea denouncing praising pain was born anyone.</p>
                    </div>
                </div>
                <div class="funfact-block-one">
                    <div class="inner-box">
                        <div class="inner">
                            <div class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""/></div>
                            <div class="count-outer count-box">
                                <span class="count-text" data-speed="1500" data-stop="99">100</span><span>%</span>
                            </div>
                        </div>
                        <h4>Satisfied Clients</h4>
                        <p>Teachings of the great explorer the truth master-builder of human.</p>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- funfact-section end --> */}


        {/* <!-- statistics-section --> */}
        <section class="statistics-section alternat-2">
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                        <div class="image-box">
                            <figure class="image"><img src="assets/images/resource/statistics-2.jpg" alt=""/></figure>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="content-box">
                            <div class="sec-title">
                                <h6>Company Reports & Statistics</h6>
                                <h2>The Impact of <br />Our Competitive Efforts</h2>
                                <p>Denouncing pleasure and praising pain was born and will give you complete great explorer of the truth the master-builder.</p>
                            </div>
                            <div class="progress-inner">
                                <div class="progress-box">
                                    <div class="bar-box">
                                        <h5>Student Visa</h5>
                                        <div class="bar">
                                            <div class="bar-inner count-bar" data-percent="78%"></div>
                                        </div>
                                        <div class="count-text">78%</div>
                                    </div>
                                </div>
                                <div class="progress-box">
                                    <div class="bar-box">
                                        <h5>Residence Visa</h5>
                                        <div class="bar">
                                            <div class="bar-inner count-bar" data-percent="92%"></div>
                                        </div>
                                        <div class="count-text">92%</div>
                                    </div>
                                </div>
                                <div class="progress-box">
                                    <div class="bar-box">
                                        <h5>Business Visa</h5>
                                        <div class="bar">
                                            <div class="bar-inner count-bar" data-percent="65%"></div>
                                        </div>
                                        <div class="count-text">65%</div>
                                    </div>
                                </div>
                                <div class="progress-box">
                                    <div class="bar-box">
                                        <h5>Tourist Visa</h5>
                                        <div class="bar">
                                            <div class="bar-inner count-bar" data-percent="86%"></div>
                                        </div>
                                        <div class="count-text">86%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="download-box">
                                <div class="icon"><i class="flaticon-pdf"></i></div>
                                <h6><a href="index-2.html">Download</a></h6>
                                <h5>Report for the Year 2020</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- statistics-section end --> */}

        </div>
<Footer/>
    </>
  )
}
