import React from 'react'
import Header from './Includes/Header'
import Footer from './Includes/Footer'
import './Enquiry.css'

export default function Enquiry() {
  return (
    <>
    <Header/>
    <div class="boxed_wrapper about">
    {/* <!-- Page Title --> */}
        <section class="page-title page-title9">
            <div class="auto-container">
                <div class="content-box clearfix">
                    <div class="title centred">
                        <h6>Enquiry Eclati</h6>
                        <h1>Immigration Process Starts Here!..</h1>
                    </div>
                    <ul class="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li><a href="/enquiry">Enquiry</a></li>
                        <li>Enquiry Eclati</li>
                    </ul>
                </div>
            </div>
        </section>
        {/* <!-- End Page Title --> */}

        {/* <!-- service-section --> */}
        {/* <section class="service-section">
            <div class="auto-container">
                <div class="sec-title centred">
                    <h6>Welcome to Eclati</h6>
                    <h2>A One-Stop Solution For All <br />Your Visa Needs</h2>
                </div>
                <div class="three-item-carousel owl-carousel owl-theme">
                    <div class="service-block-one">
                        <div class="inner-box">
                            <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-1.png);"}}></div>
                            <div class="category"><a href="index.html">Apply</a></div>
                            <div class="icon-box"><img src="assets/images/icons/icon-1.png" alt=""/></div>
                            <p>Online Application System</p>
                            <h3><a href="index.html">Apply for New Visa Online</a></h3>
                            <div class="btn-box"><a href="index.html"><span>Online Services</span></a></div>
                        </div>
                    </div>
                    <div class="service-block-one">
                        <div class="inner-box">
                            <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-2.png);"}}></div>
                            <div class="category"><a href="index.html">Suspends</a></div>
                            <div class="icon-box"><img src="assets/images/icons/icon-2.png" alt=""/></div>
                            <p>See What Your Options</p>
                            <h3><a href="index.html">Visa Expiring or Has Expired</a></h3>
                            <div class="btn-box"><a href="index.html"><span>What you need</span></a></div>
                        </div>
                    </div>
                    <div class="service-block-one">
                        <div class="inner-box">
                            <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-3.png);"}}></div>
                            <div class="category"><a href="index.html">Apply</a></div>
                            <div class="icon-box"><img src="assets/images/icons/icon-3.png" alt=""/></div>
                            <p>Report Illegal Immigration</p>
                            <h3><a href="index.html">Report All Suspcious Activities</a></h3>
                            <div class="btn-box"><a href="index.html"><span>Ready to Report</span></a></div>
                        </div>
                    </div>
                    <div class="service-block-one">
                        <div class="inner-box">
                            <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-1.png);"}}></div>
                            <div class="category"><a href="index.html">Apply</a></div>
                            <div class="icon-box"><img src="assets/images/icons/icon-1.png" alt=""/></div>
                            <p>Online Application System</p>
                            <h3><a href="index.html">Apply for New Visa Online</a></h3>
                            <div class="btn-box"><a href="index.html"><span>Online Services</span></a></div>
                        </div>
                    </div>
                    <div class="service-block-one">
                        <div class="inner-box">
                            <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-2.png);"}}></div>
                            <div class="category"><a href="index.html">Suspends</a></div>
                            <div class="icon-box"><img src="assets/images/icons/icon-2.png" alt=""/></div>
                            <p>See What Your Options</p>
                            <h3><a href="index.html">Visa Expiring or Has Expired</a></h3>
                            <div class="btn-box"><a href="index.html"><span>What you need</span></a></div>
                        </div>
                    </div>
                    <div class="service-block-one">
                        <div class="inner-box">
                            <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-3.png);"}}></div>
                            <div class="category"><a href="index.html">Apply</a></div>
                            <div class="icon-box"><img src="assets/images/icons/icon-3.png" alt=""/></div>
                            <p>Report Illegal Immigration</p>
                            <h3><a href="index.html">Report All Suspcious Activities</a></h3>
                            <div class="btn-box"><a href="index.html"><span>Ready to Report</span></a></div>
                        </div>
                    </div>
                    <div class="service-block-one">
                        <div class="inner-box">
                            <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-1.png);"}}></div>
                            <div class="category"><a href="index.html">Apply</a></div>
                            <div class="icon-box"><img src="assets/images/icons/icon-1.png" alt=""/></div>
                            <p>Online Application System</p>
                            <h3><a href="index.html">Apply for New Visa Online</a></h3>
                            <div class="btn-box"><a href="index.html"><span>Online Services</span></a></div>
                        </div>
                    </div>
                    <div class="service-block-one">
                        <div class="inner-box">
                            <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-2.png);"}}></div>
                            <div class="category"><a href="index.html">Suspends</a></div>
                            <div class="icon-box"><img src="assets/images/icons/icon-2.png" alt=""/></div>
                            <p>See What Your Options</p>
                            <h3><a href="index.html">Visa Expiring or Has Expired</a></h3>
                            <div class="btn-box"><a href="index.html"><span>What you need</span></a></div>
                        </div>
                    </div>
                    <div class="service-block-one">
                        <div class="inner-box">
                            <div class="pattern-layer" style={{backgroundImage: "url(assets/images/shape/shape-3.png);"}}></div>
                            <div class="category"><a href="index.html">Apply</a></div>
                            <div class="icon-box"><img src="assets/images/icons/icon-3.png" alt=""/></div>
                            <p>Report Illegal Immigration</p>
                            <h3><a href="index.html">Report All Suspcious Activities</a></h3>
                            <div class="btn-box"><a href="index.html"><span>Ready to Report</span></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!-- service-section end --> */}


        {/* <!-- visa-section --> */}
        <section class="visa-section bg-color-1">
            <div class="auto-container">
                <div class="sec-title centred">
                    <h6>Visa Categories</h6>
                    <h2>Enabling Your Immigration <br />Successfully</h2>
                </div>
                <div class="row clearfix">
                    <div class="col-lg-3 col-md-6 col-sm-12 visa-block">
                        <div class="visa-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><a href="visa-details.html"><img src="assets/images/resource/visa-1.jpg" alt=""/></a></figure>
                                <div class="lower-content">
                                    <div class="text">
                                        <span>01.</span>
                                        <h4><a href="visa-details.html">Student Visa</a></h4>
                                        <p>Foresee the pain and trouble that are bound ensue.</p>
                                        <ul class="list clearfix">
                                            <li><i class="flaticon-diagonal-arrow"></i>F1 Student Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>J1 Exchange Visitor Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Non-Academic Visa</li>
                                        </ul>
                                    </div>
                                    <div class="link-box">
                                        <a href="visa-details.html"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 visa-block">
                        <div class="visa-block-one wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><a href="visa-details-2.html"><img src="assets/images/resource/visa-2.jpg" alt=""/></a></figure>
                                <div class="lower-content">
                                    <div class="text">
                                        <span>02.</span>
                                        <h4><a href="visa-details-2.html">Residence Visa</a></h4>
                                        <p>Desire that they can foresee trouble bound ensue.</p>
                                        <ul class="list clearfix">
                                            <li><i class="flaticon-diagonal-arrow"></i>Permanent Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Humanitarian Residence</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Temporary Visa</li>
                                        </ul>
                                    </div>
                                    <div class="link-box">
                                        <a href="visa-details-2.html"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 visa-block">
                        <div class="visa-block-one wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><a href="visa-details-3.html"><img src="assets/images/resource/visa-3.jpg" alt=""/></a></figure>
                                <div class="lower-content">
                                    <div class="text">
                                        <span>03.</span>
                                        <h4><a href="visa-details-3.html">Business Visa</a></h4>
                                        <p>Equally blame belongs those who fail in their duty.</p>
                                        <ul class="list clearfix">
                                            <li><i class="flaticon-diagonal-arrow"></i>Business Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Employement Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Project Visa</li>
                                        </ul>
                                    </div>
                                    <div class="link-box">
                                        <a href="visa-details-3.html"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 visa-block">
                        <div class="visa-block-one wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><a href="visa-details-4.html"><img src="assets/images/resource/visa-4.jpg" alt=""/></a></figure>
                                <div class="lower-content">
                                    <div class="text">
                                        <span>04.</span>
                                        <h4><a href="visa-details-4.html">Tourist Visa</a></h4>
                                        <p>Foresee the pain and trouble that are bound ensue.</p>
                                        <ul class="list clearfix">
                                            <li><i class="flaticon-diagonal-arrow"></i>F1 Student Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>J1 Exchange Visitor Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Non-Academic Visa</li>
                                        </ul>
                                    </div>
                                    <div class="link-box">
                                        <a href="visa-details-4.html"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- visa-section end --> */}

        {/* <!-- dream-section --> */}
        <section class="dream-section">
            <div class="bg-layer"></div>
            <div class="auto-container">
                <div class="inner-container">
                    <div class="pattern-layer shape-4"></div>
                    <div class="row align-items-center clearfix">
                        <div class="col-lg-6 col-md-12 col-sm-12 left-column">
                            <div class="left-content">
                                <div class="sec-title light">
                                    <h6>Reach Your Dream</h6>
                                    <h2>Get the Right Help</h2>
                                </div>
                                <div class="text">
                                    <p>Explain to you how all this mistaken idea of denouncing all <br />praising pain was born and completed.</p>
                                    <a href="index.html"><span>More Details</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 right-column">
                            <div class="right-content">
                                <ul class="list clearfix">
                                    <li><i class="flaticon-star"></i><a href="index.html">Entering & Leaving From Country</a></li>
                                    <li><i class="flaticon-star"></i><a href="index.html">Visas</a></li>
                                    <li><i class="flaticon-star"></i><a href="index.html">Country Citizenship</a></li>
                                    <li><i class="flaticon-star"></i><a href="index.html">Settling In Country</a></li>
                                    <li><i class="flaticon-star"></i><a href="index.html">Help & Support</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- dream-section end --> */}


        {/* <!-- about-section --> */}
        <section class="about-section">
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                        <div class="image-box">
                            <div class="row clearfix">
                                <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                    <figure class="image"><img src="assets/images/resource/about-1.jpg" alt=""/></figure>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                    <div class="text-inner">
                                        <div class="shape" style={{backgroundImage: "url(assets/images/shape/shape-5.png);"}}></div>
                                        <div class="text">
                                            <h2>14</h2>
                                            <h6>Years of <br />Experienced</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                    <figure class="image"><img src="assets/images/resource/about-2.jpg" alt=""/></figure>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                    <figure class="image"><img src="assets/images/resource/about-3.jpg" alt=""/></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="content-box">
                            <div class="sec-title">
                                <h6>About Eclati</h6>
                                <h2>We’re Trusted <br />Immigration Consultant</h2>
                            </div>
                            <div class="inner">
                                <div class="icon"><img src="assets/images/icons/icon-4.png" alt=""/></div>
                                <h6>U.S based</h6>
                                <h3>Immigration Consultant Agency.</h3>
                            </div>
                            <div class="text">
                                <p>All this mistaken idea of denouncing pleasure and praising pain was born and will give you a complete account of the system, and expound the actual teach- ings of the great explorer of the truth, the master-builder of human happiness. No one rejects dislikes or avoids pleasures itself because it is all pleasure but because those who do not know how pursue those who fail their duty through weakness of will, which is the same as saying.</p>
                            </div>
                            <div class="btn-box">
                                <a href="about.html" class="theme-btn btn-two">More Details <i class="flaticon-next"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- about-section end --> */}


        {/* <!-- coaching-section --> */}
        <section class="coaching-section">
            <div class="auto-container">
                <div class="sec-title">
                    <h6>Coaching Classes</h6>
                    <h2>Our Training & Certification</h2>
                    <div class="btn-box"><a href="coaching.html" class="theme-btn btn-two">View More <i class="flaticon-next"></i></a></div>
                </div>
                <div class="row clearfix">
                    <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                        <div class="coaching-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><img src="assets/images/resource/coaching-1.jpg" alt=""/></figure>
                                <div class="content-box">
                                    <div class="icon-box"><a href="coaching-details.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details.html">ielts</a></h5>
                                    <h4><a href="coaching-details.html">Coaching</a></h4>
                                </div>
                                <div class="overlay-content">
                                    <div class="icon-box"><a href="coaching-details.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details.html">ielts</a></h5>
                                    <h4><a href="coaching-details.html">Coaching</a></h4>
                                    <p>Extremely painful again is there anyone loves or pursues.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                        <div class="coaching-block-one wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><img src="assets/images/resource/coaching-2.jpg" alt=""/></figure>
                                <div class="content-box">
                                    <div class="icon-box"><a href="coaching-details-2.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-2.html">toefl</a></h5>
                                    <h4><a href="coaching-details-2.html">Coaching</a></h4>
                                </div>
                                <div class="overlay-content">
                                    <div class="icon-box"><a href="coaching-details-2.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-2.html">toefl</a></h5>
                                    <h4><a href="coaching-details-2.html">Coaching</a></h4>
                                    <p>Extremely painful again is there anyone loves or pursues.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                        <div class="coaching-block-one wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><img src="assets/images/resource/coaching-3.jpg" alt=""/></figure>
                                <div class="content-box">
                                    <div class="icon-box"><a href="coaching-details-3.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-3.html">pte</a></h5>
                                    <h4><a href="coaching-details-3.html">Coaching</a></h4>
                                </div>
                                <div class="overlay-content">
                                    <div class="icon-box"><a href="coaching-details-3.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-3.html">pte</a></h5>
                                    <h4><a href="coaching-details-3.html">Coaching</a></h4>
                                    <p>Extremely painful again is there anyone loves or pursues.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 coaching-block">
                        <div class="coaching-block-one wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><img src="assets/images/resource/coaching-4.jpg" alt=""/></figure>
                                <div class="content-box">
                                    <div class="icon-box"><a href="coaching-details-4.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-4.html">oet</a></h5>
                                    <h4><a href="coaching-details-4.html">Coaching</a></h4>
                                </div>
                                <div class="overlay-content">
                                    <div class="icon-box"><a href="coaching-details-4.html"><i class="flaticon-diagonal-arrow-1"></i></a></div>
                                    <h5><a href="coaching-details-4.html">oet</a></h5>
                                    <h4><a href="coaching-details-4.html">Coaching</a></h4>
                                    <p>Extremely painful again is there anyone loves or pursues.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- coaching-section end --> */}



        {/* <!-- destination-section --> */}
        <section class="destination-section">
            <div class="bg-layer bg-color-1"></div>
            <div class="auto-container">
                <div class="inner-container">
                    <figure class="image"><img src="assets/images/resource/destination-1.jpg" alt=""/></figure>
                    <div class="content-box">
                        <div class="shape">
                            <div class="shape-1" style={{backgroundImage: "url(assets/images/shape/shape-6.png);"}}></div>
                            <div class="shape-2" style={{backgroundImage: "url(assets/images/shape/shape-7.png);"}}></div>
                        </div>
                        <h2>Fly Your Dream Destination</h2>
                        <p>Idea of denouncing pleasure & praising pain was born.</p>
                        <ul class="list clearfix">
                            <li><i class="flaticon-checkbox-mark"></i>Entering & Leaving From Country</li>
                            <li><i class="flaticon-checkbox-mark"></i>Visas</li>
                            <li><i class="flaticon-checkbox-mark"></i>Country Citizenship</li>
                        </ul>
                        <div class="btn-box">
                            <a href="contact.html" class="theme-btn btn-one">Contact Us<i class="flaticon-next"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- destination-section end --> */}


        {/* <!-- chooseus-section --> */}
        <section class="chooseus-section bg-color-1 centred">
            <div class="auto-container">
                <div class="sec-title centred">
                    <h6>Why Choose Us</h6>
                    <h2>Offer Tailor Made Services That <br />Our Client Requires</h2>
                </div>
                <div class="inner-content">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one">
                                <div class="inner-box">
                                    <div class="icon-box"><img src="assets/images/icons/icon-5.png" alt=""/></div>
                                    <h4>Direct Interviews</h4>
                                    <p>Expound actual teachings too the great explorers truth.</p>
                                    <a href="index.html"><span>Read&nbsp;More</span><i class="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one">
                                <div class="inner-box">
                                    <div class="icon-box"><img src="assets/images/icons/icon-6.png" alt=""/></div>
                                    <h4>Faster Processing</h4>
                                    <p>Give you a completed account the expound the teachings.</p>
                                    <a href="index.html"><span>Read&nbsp;More</span><i class="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one">
                                <div class="inner-box">
                                    <div class="icon-box"><img src="assets/images/icons/icon-7.png" alt=""/></div>
                                    <h4>Visa Assistance</h4>
                                    <p>Expound actual teachings too the great explorers truth.</p>
                                    <a href="index.html"><span>Read&nbsp;More</span><i class="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one">
                                <div class="inner-box">
                                    <div class="icon-box"><img src="assets/images/icons/icon-8.png" alt=""/></div>
                                    <h4>Cost-Effective</h4>
                                    <p>Give you a completed account the expound the teachings.</p>
                                    <a href="index.html"><span>Read&nbsp;More</span><i class="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lower-box">
                    <h2>Guiding on preparing for <a href="index.html">eligibility exams to visa</a> assistance.</h2>
                    <a href="coaching.html" class="theme-btn btn-two">Get Assistance <i class="flaticon-next"></i></a>
                </div>
            </div>
        </section>
        {/* <!-- chooseus-section end --> */}


        {/* <!-- team-section --> */}
        {/* <section class="team-section centred">
            <div class="auto-container">
                <div class="sec-title centred">
                    <h6>Expert Team Members</h6>
                    <h2>Our Team at Your Service</h2>
                </div>
                <div class="row clearfix">
                    <div class="col-lg-4 col-md-6 col-sm-12 team-block">
                        <div class="team-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <div class="image-box">
                                    <figure class="image"><img src="assets/images/team/team-1.jpg" alt=""/></figure>
                                    <ul class="list clearfix">
                                        <li class="share-option">
                                            <span class="share-icon"><i class="flaticon-share"></i></span>
                                            <ul class="share-links clearfix">
                                                <li><a href="index.html"><i class="fab fa-linkedin-in"></i></a></li>
                                                <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                                            </ul>
                                        </li>
                                        <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                                    </ul>
                                </div>
                                <div class="lower-content">
                                    <h4><a href="index.html">Reggie Hunter</a></h4>
                                    <span class="designation">Founder & Managing Partner</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 team-block">
                        <div class="team-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <div class="image-box">
                                    <figure class="image"><img src="assets/images/team/team-2.jpg" alt=""/></figure>
                                    <ul class="list clearfix">
                                        <li class="share-option">
                                            <span class="share-icon"><i class="flaticon-share"></i></span>
                                            <ul class="share-links clearfix">
                                                <li><a href="index.html"><i class="fab fa-linkedin-in"></i></a></li>
                                                <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                                            </ul>
                                        </li>
                                        <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                                    </ul>
                                </div>
                                <div class="lower-content">
                                    <h4><a href="index.html">Roman Frederick</a></h4>
                                    <span class="designation">Regional Manager, Operations</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 team-block">
                        <div class="team-block-one wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <div class="image-box">
                                    <figure class="image"><img src="assets/images/team/team-3.jpg" alt=""/></figure>
                                    <ul class="list clearfix">
                                        <li class="share-option">
                                            <span class="share-icon"><i class="flaticon-share"></i></span>
                                            <ul class="share-links clearfix">
                                                <li><a href="index.html"><i class="fab fa-linkedin-in"></i></a></li>
                                                <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                                            </ul>
                                        </li>
                                        <li><a href="mailto:info@example.com"><i class="flaticon-open-envelope"></i></a></li>
                                    </ul>
                                </div>
                                <div class="lower-content">
                                    <h4><a href="index.html">Ruby Valentina</a></h4>
                                    <span class="designation">Immigration Consultants</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!-- team-section end --> */}


        {/* <!-- testimonial-section --> */}
        <section class="testimonial-section" style={{backgroundImage: "url(assets/images/background/testimonial-bg.jpg);"}}>
            <div class="auto-container">
                <div class="sec-title light">
                    <h6>Client Reviews</h6>
                    <h2>Feedback From Our Clients</h2>
                </div>
                <div class="three-item-carousel owl-carousel owl-theme owl-dots-none">
                    <div class="testimonial-block-one">
                        <div class="inner-box">
                            <div class="text">
                                <h4>Smooth & Efficient Service!...</h4>
                                <p>Eclati Consultancy is definitely a highly recommended canadian an migration agency. A big applause & very grateful to Mr.Richardson for efforts and assistance.</p>
                            </div>
                            <div class="lower-box">
                                <div class="quote"><img src="assets/images/icons/quote-1.png" alt=""/></div>
                                <figure class="author-thumb"><img src="assets/images/resource/testimonial-1.jpg" alt=""/></figure>
                                <ul class="rating clearfix">
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                </ul>
                                <h4>Silverster Scott, <span>Netherland</span></h4>
                                <span class="date">October 10, 2021</span>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-block-one">
                        <div class="inner-box">
                            <div class="text">
                                <h4>Thank You Eclati!...</h4>
                                <p>Eclati visa consultancy is the best we came across while doing market research & migrating to Canada. All informations conveyed were correct genuine and helpful.</p>
                            </div>
                            <div class="lower-box">
                                <div class="quote"><img src="assets/images/icons/quote-1.png" alt=""/></div>
                                <figure class="author-thumb"><img src="assets/images/resource/testimonial-2.jpg" alt=""/></figure>
                                <ul class="rating clearfix">
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                </ul>
                                <h4>Nora Penelope, <span>Switcherland</span></h4>
                                <span class="date">October 09, 2021</span>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-block-one">
                        <div class="inner-box">
                            <div class="text">
                                <h4>Highly Recommended!...</h4>
                                <p>Awesome customer service, they know what they are doing. Straight to the point, help with the forms if you need it. Amazing results always. We 100% recommend to others.</p>
                            </div>
                            <div class="lower-box">
                                <div class="quote"><img src="assets/images/icons/quote-1.png" alt=""/></div>
                                <figure class="author-thumb"><img src="assets/images/resource/testimonial-3.jpg" alt=""/></figure>
                                <ul class="rating clearfix">
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                    <li><i class="flaticon-star"></i></li>
                                </ul>
                                <h4>Arlo Sebastian, <span>Australia</span></h4>
                                <span class="date">October 09, 2021</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- testimonial-section end --> */}


        {/* <!-- news-section --> */}
        <section class="news-section">
            <div class="auto-container">
                <div class="sec-title centred">
                    <h6>News & Updates</h6>
                    <h2>Read Our Latest Insights</h2>
                </div>
                <div class="row clearfix">
                    <div class="col-lg-4 col-md-6 col-sm-12 news-block">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <div class="image-box">
                                    <h2>14<span>Oct</span></h2>
                                    <figure class="image"><a href="blog-details.html"><img src="assets/images/news/news-4.jpg" alt=""/></a></figure>
                                </div>
                                <div class="lower-content">
                                    <div class="author-box">
                                        <figure class="author-thumb"><img src="assets/images/news/author-1.jpg" alt=""/></figure>
                                        <h6><a href="blog-details.html">Immigration</a></h6>
                                        <span>Post By: Colmin O'Neill</span>
                                    </div>
                                    <h4><a href="blog-details.html">Citizenship Concept on How to Become a UK Citizen</a></h4>
                                    <p>Laborious physical exercise excepts obtin some advantage from...</p>
                                    <div class="lower-box clearfix">
                                        <div class="link pull-left"><a href="blog-details.html"><span>REad More</span></a></div>
                                        <ul class="info clearfix pull-right">
                                            <li><i class="far fa-heart"></i><a href="blog-details.html">36</a></li>
                                            <li><i class="far fa-comment"></i><a href="blog-details.html">08</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 news-block">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <div class="image-box">
                                    <h2>05<span>Oct</span></h2>
                                    <figure class="image"><a href="blog-details.html"><img src="assets/images/news/news-5.jpg" alt=""/></a></figure>
                                </div>
                                <div class="lower-content">
                                    <div class="author-box">
                                        <figure class="author-thumb"><img src="assets/images/news/author-2.jpg" alt=""/></figure>
                                        <h6><a href="blog-details.html">Resident Visa</a></h6>
                                        <span>Post By: Layla Madison</span>
                                    </div>
                                    <h4><a href="blog-details.html">7 Benefits of Being a Permanent Resident in Canada</a></h4>
                                    <p>Foresee the pain and trouble that are bound ensue equal blame belongs...</p>
                                    <div class="lower-box clearfix">
                                        <div class="link pull-left"><a href="blog-details.html"><span>REad More</span></a></div>
                                        <ul class="info clearfix pull-right">
                                            <li><i class="far fa-heart"></i><a href="blog-details.html">12</a></li>
                                            <li><i class="far fa-comment"></i><a href="blog-details.html">03</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 news-block">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <div class="image-box">
                                    <h2>26<span>Sep</span></h2>
                                    <figure class="image"><a href="blog-details.html"><img src="assets/images/news/news-6.jpg" alt=""/></a></figure>
                                </div>
                                <div class="lower-content">
                                    <div class="author-box">
                                        <figure class="author-thumb"><img src="assets/images/news/author-3.jpg" alt=""/></figure>
                                        <h6><a href="blog-details.html">News & Tips</a></h6>
                                        <span>Post By: Boone Gerardo</span>
                                    </div>
                                    <h4><a href="blog-details.html">Three Common Mistakes in Visa Applications</a></h4>
                                    <p>Laborious physical exercise excepts obtain some advantage from...</p>
                                    <div class="lower-box clearfix">
                                        <div class="link pull-left"><a href="blog-details.html"><span>REad More</span></a></div>
                                        <ul class="info clearfix pull-right">
                                            <li><i class="far fa-heart"></i><a href="blog-details.html">06</a></li>
                                            <li><i class="far fa-comment"></i><a href="blog-details.html">15</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- news-section end --> */}


        {/* <!-- clients-section --> */}
        <section class="clients-section">
            <div class="outer-container">
                <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                    <figure class="clients-logo-box">
                        <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt=""/></a>
                        <span class="logo-title"><a href="index.html">Visit Website</a></span>
                    </figure>
                    <figure class="clients-logo-box">
                        <a href="index.html"><img src="assets/images/clients/clients-logo-2.png" alt=""/></a>
                        <span class="logo-title"><a href="index.html">Visit Website</a></span>
                    </figure>
                    <figure class="clients-logo-box">
                        <a href="index.html"><img src="assets/images/clients/clients-logo-3.png" alt=""/></a>
                        <span class="logo-title"><a href="index.html">Visit Website</a></span>
                    </figure>
                    <figure class="clients-logo-box">
                        <a href="index.html"><img src="assets/images/clients/clients-logo-4.png" alt=""/></a>
                        <span class="logo-title"><a href="index.html">Visit Website</a></span>
                    </figure>
                    <figure class="clients-logo-box">
                        <a href="index.html"><img src="assets/images/clients/clients-logo-5.png" alt=""/></a>
                        <span class="logo-title"><a href="index.html">Visit Website</a></span>
                    </figure>
                    <figure class="clients-logo-box">
                        <a href="index.html"><img src="assets/images/clients/clients-logo-6.png" alt=""/></a>
                        <span class="logo-title"><a href="index.html">Visit Website</a></span>
                    </figure>
                </div>
            </div>
        </section>
        {/* <!-- clients-section end --> */}


        {/* <!-- contact-section end --> */}
        <section class="contact-section bg-color-1">
            <div class="pattern-layer shape-8"></div>
            <div class="bg-layer contact-bg"></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-xl-6 col-lg-12 offset-xl-6 content-column">
                        <div class="content-box">
                            <div class="sec-title">
                                <h6>eligible to apply</h6>
                                <h2>Check Your Eligibility</h2>
                                <p>Please, fulfill the form to get a consultation. After processing the data, apersonal manager will contact you.</p>
                            </div>
                            <div class="form-inner">
                                <form action="index.html" method="post">
                                    <div class="row clearfix">
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="fname" placeholder="First Name" required/>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="lname" placeholder="Last Name" required/>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="code" placeholder="Country Code" required/>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="phone" placeholder="Phone" required/>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <div class="select-box">
                                                <select class="wide">
                                                   <option data-display="Marital Status">Marital Status</option>
                                                   <option value="1">Married</option>
                                                   <option value="2">Unmarried</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <div class="select-box">
                                                <select class="wide">
                                                   <option data-display="Monthly Income">Monthly Income</option>
                                                   <option value="1">$1500</option>
                                                   <option value="2">$2000</option>
                                                   <option value="3">$2500</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="email" name="email" placeholder="Email Address" required/>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" name="occupation" placeholder="Current Occupation" required/>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <button class="theme-btn btn-two">Check Now <i class="flaticon-next"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- contact-section end --> */}


        </div>
    <Footer/>
    </>
  )
}
