import React from 'react'
import Header from './Includes/Header'
import Footer from './Includes/Footer'
import './Visa.css'
export default function Visa() {
  return (
    <>
    <Header/>
    <div class="boxed_wrapper about">
    {/* <!-- Page Title --> */}
        <section class="page-title page-title5">
            <div class="auto-container">
                <div class="content-box clearfix">
                    <div class="title centred">
                        <h6>VISA CATEGORIES</h6>
                        <h1>Visa That Might Suit Your Needs</h1>
                    </div>
                    <ul class="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li>Visa</li>
                    </ul>
                </div>
            </div>
        </section>
        {/* <!-- End Page Title --> */}

        {/* <!-- about-style-five --> */}
        <section class="about-style-five">
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                        <div class="image-box">
                            <figure class="image"><img src="assets/images/resource/about-8.jpg" alt=""/></figure>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="content_block_three">
                            <div class="content-box">
                                <div class="sec-title">
                                    <h6>What We Offer</h6>
                                    <h2>Solution From <br />Leading Visa Consultant In United States</h2>
                                </div>
                                <div class="text">
                                    <p>Pleasure and praising pain was born and will give you complete account the of the great explorer the truth, the master-builder mistaken idea denouncing pleasure and praising pain was born.</p>
                                </div>
                                <div class="inner">
                                    <div class="progress-box">
                                        <div class="piechart"  data-fg-color="#e94d4e" data-value=".84"></div>
                                        <h4>Year of <br />2020</h4>
                                    </div>
                                    <h2>84%</h2>
                                    <h3>Visa’s are Approved</h3>
                                    <p>Long established fact that a reader will be distracted and will give you complete account.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- about-style-five end --> */}


        {/* <!-- visa-section --> */}
        <section class="visa-section bg-color-1">
            <div class="auto-container">
                <div class="sec-title centred">
                    <h6>Visa Categories</h6>
                    <h2>Enabling Your Immigration <br />Successfully</h2>
                </div>
                <div class="row clearfix">
                    <div class="col-lg-3 col-md-6 col-sm-12 visa-block">
                        <div class="visa-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><a href="/visa-details"><img src="assets/images/resource/visa-1.jpg" alt=""/></a></figure>
                                <div class="lower-content">
                                    <div class="text">
                                        <span>01.</span>
                                        <h4><a href="/visa-details">Student Visa</a></h4>
                                        <p>Foresee the pain and trouble that are bound ensue.</p>
                                        <ul class="list clearfix">
                                            <li><i class="flaticon-diagonal-arrow"></i>F1 Student Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>J1 Exchange Visitor Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Non-Academic Visa</li>
                                        </ul>
                                    </div>
                                    <div class="link-box">
                                        <a href="/visa-details"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 visa-block">
                        <div class="visa-block-one wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><a href="/visa-details-2"><img src="assets/images/resource/visa-2.jpg" alt=""/></a></figure>
                                <div class="lower-content">
                                    <div class="text">
                                        <span>02.</span>
                                        <h4><a href="/visa-details-2">Residence Visa</a></h4>
                                        <p>Desire that they can foresee trouble bound ensue.</p>
                                        <ul class="list clearfix">
                                            <li><i class="flaticon-diagonal-arrow"></i>Permanent Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Humanitarian Residence</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Temporary Visa</li>
                                        </ul>
                                    </div>
                                    <div class="link-box">
                                        <a href="/visa-details-2"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 visa-block">
                        <div class="visa-block-one wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><a href="/visa-details-3"><img src="assets/images/resource/visa-3.jpg" alt=""/></a></figure>
                                <div class="lower-content">
                                    <div class="text">
                                        <span>03.</span>
                                        <h4><a href="/visa-details-3">Business Visa</a></h4>
                                        <p>Equally blame belongs those who fail in their duty.</p>
                                        <ul class="list clearfix">
                                            <li><i class="flaticon-diagonal-arrow"></i>Business Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Employement Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Project Visa</li>
                                        </ul>
                                    </div>
                                    <div class="link-box">
                                        <a href="/visa-details-3"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 visa-block">
                        <div class="visa-block-one wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                <figure class="image"><a href="/visa-details-4"><img src="assets/images/resource/visa-4.jpg" alt=""/></a></figure>
                                <div class="lower-content">
                                    <div class="text">
                                        <span>04.</span>
                                        <h4><a href="/visa-details-4">Tourist Visa</a></h4>
                                        <p>Foresee the pain and trouble that are bound ensue.</p>
                                        <ul class="list clearfix">
                                            <li><i class="flaticon-diagonal-arrow"></i>F1 Student Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>J1 Exchange Visitor Visa</li>
                                            <li><i class="flaticon-diagonal-arrow"></i>Non-Academic Visa</li>
                                        </ul>
                                    </div>
                                    <div class="link-box">
                                        <a href="/visa-details-4"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- visa-section end --> */}


        {/* <!-- statistics-section --> */}
        <section class="statistics-section">
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                        <div class="image-box">
                            <figure class="image"><img src="assets/images/resource/statistics-1.jpg" alt=""/></figure>
                            <figure class="image image-2"><img src="assets/images/resource/statistics-1.png" alt=""/></figure>
                            <div class="curve-text">
                                {/* <div class="icon-box"><img src="assets/images/icons/icon-34.png" alt=""/></div> */}
                                {/* <span class="curved-circle">Immigo Imigration Agency Esytablished 1985 . </span> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="content-box">
                            <div class="sec-title">
                                <h6>Company Reports & Statistics</h6>
                                <h2>The Impact of <br />Our Competitive Efforts</h2>
                                <p>Denouncing pleasure and praising pain was born and will give you complete great explorer of the truth the master-builder.</p>
                            </div>
                            <div class="progress-inner">
                                <div class="progress-box">
                                    <div class="bar-box">
                                        <h5>Student Visa</h5>
                                        <div class="bar">
                                            <div class="bar-inner count-bar" data-percent="78%"></div>
                                        </div>
                                        <div class="count-text">78%</div>
                                    </div>
                                </div>
                                <div class="progress-box">
                                    <div class="bar-box">
                                        <h5>Residence Visa</h5>
                                        <div class="bar">
                                            <div class="bar-inner count-bar" data-percent="92%"></div>
                                        </div>
                                        <div class="count-text">92%</div>
                                    </div>
                                </div>
                                <div class="progress-box">
                                    <div class="bar-box">
                                        <h5>Business Visa</h5>
                                        <div class="bar">
                                            <div class="bar-inner count-bar" data-percent="65%"></div>
                                        </div>
                                        <div class="count-text">65%</div>
                                    </div>
                                </div>
                                <div class="progress-box">
                                    <div class="bar-box">
                                        <h5>Tourist Visa</h5>
                                        <div class="bar">
                                            <div class="bar-inner count-bar" data-percent="86%"></div>
                                        </div>
                                        <div class="count-text">86%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="download-box">
                                <div class="icon"><i class="flaticon-pdf"></i></div>
                                <h6><a href="index-2.html">Download</a></h6>
                                <h5>Report for the Year 2020</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- statistics-section end --> */}


        {/* <!-- chooseus-section --> */}
        <section class="chooseus-section bg-color-1 centred">
            <div class="auto-container">
                <div class="sec-title centred">
                    <h6>Why Choose Us</h6>
                    <h2>Offer Tailor Made Services That <br />Our Client Requires</h2>
                </div>
                <div class="inner-content">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one">
                                <div class="inner-box">
                                    <div class="icon-box"><img src="assets/images/icons/icon-5.png" alt=""/></div>
                                    <h4>Direct Interviews</h4>
                                    <p>Expound actual teachings too the great explorers truth.</p>
                                    <a href="index.html"><span>Read&nbsp;More</span><i class="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one">
                                <div class="inner-box">
                                    <div class="icon-box"><img src="assets/images/icons/icon-6.png" alt=""/></div>
                                    <h4>Faster Processing</h4>
                                    <p>Give you a completed account the expound the teachings.</p>
                                    <a href="index.html"><span>Read&nbsp;More</span><i class="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one">
                                <div class="inner-box">
                                    <div class="icon-box"><img src="assets/images/icons/icon-7.png" alt=""/></div>
                                    <h4>Visa Assistance</h4>
                                    <p>Expound actual teachings too the great explorers truth.</p>
                                    <a href="index.html"><span>Read&nbsp;More</span><i class="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one">
                                <div class="inner-box">
                                    <div class="icon-box"><img src="assets/images/icons/icon-8.png" alt=""/></div>
                                    <h4>Cost-Effective</h4>
                                    <p>Give you a completed account the expound the teachings.</p>
                                    <a href="index.html"><span>Read&nbsp;More</span><i class="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lower-box">
                    <h2>Guiding on preparing for <a href="index.html">eligibility exams to visa</a> assistance.</h2>
                    <a href="coaching.html" class="theme-btn btn-two">Get Assistance <i class="flaticon-next"></i></a>
                </div>
            </div>
        </section>
        {/* <!-- chooseus-section end --> */}

        </div>
    <Footer/>
    </>
  )
}
